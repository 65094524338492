import React, { useContext, useEffect, useState } from 'react'

//@mui
import {
  Box,
  Container,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'

//custom
import Iconify from 'src/components/Iconify'
import useTabs from 'src/hooks/useTabs'
import { ResellersContext } from 'src/contexts/ResellersContext'
import ResellerGeneral from './ResellerGeneral'

import ResellerUserList from './User/ResellerUserList'
import ResellerNotes from './Notes/ResellerNotes'
import ResellerCustomer from './Customers/ResellerCustomers'
import { Button } from '@mui/material'
import ResellerStatusUpdateModal from './ResellerStatusUpdateModal'

function ResellerDetails() {
  const { resellers, currentCompany, filterItems } = useContext(ResellersContext)!
  console.log(resellers, currentCompany)
  const [reseller, setReseller] = useState(currentCompany)
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false)

  useEffect(() => {
    setReseller(currentCompany)
  }, [resellers, currentCompany])

  //tabs
  const { currentTab, onChangeTab, setCurrentTab } = useTabs('general')

  const RESELLER_TABS = [
    {
      value: 'general',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <ResellerGeneral reseller={reseller} />,
      label: 'Reseller Details',
    },
    {
      value: 'customer',
      icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
      // component: <ResellerUserList reseller={reseller} />,
      component: <ResellerCustomer reseller={reseller} />,

      label: 'Customers',
    },
    {
      value: 'user',
      icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
      component: <ResellerUserList reseller={reseller} />,
      label: 'Users',
    },
    {
      value: 'notes',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <ResellerNotes reseller={reseller} />,
      label: 'Notes',
    },
  ]

  useEffect(() => {
    const found = filterItems.find((itm: any) => itm.label.includes('user'))
    if (found) {
      setCurrentTab('user')
    }
  }, [currentCompany])

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction="row" alignItems={'center'}>
            <BusinessIcon fontSize="large" className="me-3 text-muted" />
            {!reseller?.name ? (
              <>
                <Skeleton animation="wave" height={60} width={300} />
              </>
            ) : (
              <Typography variant="h4">{reseller?.name}</Typography>
            )}
          </Stack>

          {reseller?.name && (
            <Tooltip
              title={`${
                reseller?.status === 'Active' && reseller?.isActive
                  ? 'Deactivate Reseller'
                  : 'Activate Reseller'
              }`}
            >
              <Button
                variant="outlined"
                sx={{ ml: 3, px: 1, py: 0.5, my: 'auto' }}
                color={
                  reseller?.status === 'Active' && reseller?.isActive
                    ? 'success'
                    : 'warning'
                }
                // startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => setOpenStatusUpdate(true)}
              >
                <span>
                  {reseller?.status === 'Active' && reseller?.isActive
                    ? 'Active'
                    : 'Activate'}
                </span>
              </Button>
            </Tooltip>
          )}
        </Stack>
        <Stack direction={'row'} spacing={2} alignItems="center"></Stack>
      </Box>

      <Tabs
        className="mt-3 px-1"
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {RESELLER_TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label}
            // icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
      {RESELLER_TABS.map((tab) => {
        const isMatched = tab.value === currentTab
        return (
          isMatched && (
            <Box key={Math.floor(100000 + Math.random() * 900000)} className="px-1">
              {tab.component}
            </Box>
          )
        )
      })}
      <ResellerStatusUpdateModal
        openProps={{ openStatusUpdate, setOpenStatusUpdate }}
        companyName={reseller?.name}
        id={reseller?.id as any}
        status={reseller?.status}
        isActive={reseller?.isActive}
        subdomain={reseller?.subdomain}
      />
    </Container>
  )
}

export default ResellerDetails
