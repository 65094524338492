import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

// @mui
import { styled } from '@mui/material/styles'
import {
  Box,
  Card,
  Stack,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'

// components
import Page from '../../components/Page'

// sections

import LogoBlackText from 'src/components/LogoBlackText'
import { FormProvider } from 'src/components/hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { put } from 'src/utils/httpMethods'
import useAuth from 'src/hooks/useAuth'
import { useContext, useState } from 'react'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import SnackbarTopLevel from 'src/sections/@dashboard/SnackbarTopLevel'
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInput from 'react-phone-input-2'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 0,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------
export const auth_app = [
  {
    label: 'Google Authenticator',
    value: 'Google Authenticator',
  },
  {
    label: 'MS Authenticator',
    value: 'MS Authenticator',
  },
  {
    label: 'Duo',
    value: 'Duo App',
  },
]
function MultiFactorAuth() {
  const { setMfaStatus, mfaStatus } = useAuth()
  const [loading, setLoading] = useState(false)

  console.log(mfaStatus)

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const navigate = useNavigate()
  const mfaSchema = Yup.object().shape({
    authApp: Yup.string().required(
      'Select an Authenticator app of your preference before preceeding'
    ),

    phone: Yup.object().when([], (values) => {
      if (mfaStatus && mfaStatus.phone && mfaStatus.phone.phoneNumber === '') {
        return Yup.object().shape({
          countryCode: Yup.string().required('Country code is required'),
          phoneNumber: Yup.string()
            .min(10, 'Please enter a valid phone number')
            .max(12, 'Please enter a valid phone number')
            .required('Phone number is required'),
        })
      } else {
        return Yup.object().shape({
          countryCode: Yup.string(),
          phoneNumber: Yup.string(),
        })
      }
    }),
  })
  const methods = useForm({
    resolver: yupResolver(mfaSchema),
    defaultValues: {
      authApp: '',
      phone: {
        countryCode: '',
        phoneNumber: '',
      },
    },
  })
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods

  const onSubmit = async (data: any) => {
    console.log(data)
    setLoading(true)
    const finaldata = mfaStatus.phone.phoneNumber
      ? {
          MFA: {
            enabled: false,
            authenticatorApp: data.authApp,
          },
        }
      : {
          MFA: {
            enabled: false,
            authenticatorApp: data.authApp,
          },
          phone: data.phone,
        }
    try {
      await put(`${process.env.REACT_APP_HOST_API_URL}/auth/mfa`, finaldata, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then((res: any) => {
        setLoading(false)
        setMfaStatus((prev) => ({
          ...prev,
          authenticatorApp: res.MFA.authenticatorApp,
        }))
        navigate(
          data.authApp === 'Duo App' ? '/auth/enroll-duo' : '/auth/verify-otp'
        )
      })
    } catch (e) {
      setSnackbarProps({
        open: true,
        message:
          e.message ||
          'Error in setting MFA app please try again or check your network connection',
        severity: 'error',
      })
      setLoading(false)
    }
  }
  const handleChange = (phone: any, fPhone: any) => {
    let mNumber = phone.slice(fPhone.dialCode.length)
    let cCode = fPhone.dialCode
    setValue('phone', { countryCode: cCode, phoneNumber: mNumber })
  }
  console.log(errors)
  return (
    <Page title="MFA" sx={{ bgcolor: '#F9FAFB ' }}>
      <SnackbarTopLevel />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RootStyle>
          <HeaderStyle>
            <LogoBlackText />
          </HeaderStyle>
          <Container maxWidth="sm">
            <ContentStyle>
              <Card elevation={6}>
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography variant="h3" sx={{ mt: 2 }} gutterBottom>
                      Secure Your Account
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      Select MFA Method
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={{ m: 3 }}>
                  <Stack spacing={3}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Typography variant="subtitle2" sx={{ mt: 1 }}>
                        Select Your Preferred Authenticator App
                      </Typography>
                      <FormControl>
                        <InputLabel>Select Authentication App </InputLabel>
                        <Select
                          fullWidth
                          label="Select Authentication App"
                          name="authApp"
                          onChange={(e: any) => setValue('authApp', e.target.value)}
                        >
                          {auth_app.map((app) => (
                            <MenuItem value={app.value} key={app.value}>
                              {app.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errors.authApp && (
                        <Typography variant="body2" color={'error'} sx={{ mt: 0 }}>
                          {errors.authApp.message}
                        </Typography>
                      )}
                    </Stack>
                    <Stack
                      spacing={2}
                      hidden={mfaStatus.phone.phoneNumber ? true : false}
                    >
                      <Typography variant="subtitle2">Phone Number</Typography>
                      <Box>
                        <PhoneInput
                          country={'in'}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            // autoFocus: true,
                          }}
                          inputStyle={{
                            borderColor: errors.phone?.message ? 'red' : '',
                            padding: '12.5px 14px 15.5px 60px',
                            width: '100%',
                          }}
                          onChange={(phone, formPhone) => {
                            handleChange(phone, formPhone)
                          }}
                          placeholder="Enter Country code followed by phone number"
                        />
                      </Box>
                      <Typography
                        color="error"
                        sx={{
                          marginTop: '8px',
                          fontSize: '0.75rem',
                        }}
                      >
                        {errors.phone?.phoneNumber?.message}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Box sx={{ m: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={loading}>
                    Next
                  </LoadingButton>
                </Box>
              </Card>
            </ContentStyle>
          </Container>
        </RootStyle>
      </FormProvider>
    </Page>
  )
}

export default MultiFactorAuth
