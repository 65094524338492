import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormProvider, RHFTextField } from '../../../components/hook-form'

import { useNavigate } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import useReacaptcha from 'src/hooks/useReacaptcha'
import { Box, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { Card } from '@mui/material'
import { FormHelperText } from '@mui/material'
import RHFOTPInput from 'src/components/hook-form/RHFOTPInput'

type FormValuesProps = {
  code1?: string
  code2?: string
  code3?: string
  code4?: string
  code5?: string
  code6?: string
  otp?: string
}
type ValueNames = 'code1' | 'code2' | 'code3' | 'code4' | 'code5' | 'code6'

function VerifyOTP() {
  const [useBackupCode, setUseBackupCode] = useState(false)

  const verifyCodeSchema = yup.object().shape({
    code1: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema : schema.required('Code is required')
      ),
    code2: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema : schema.required('Code is required')
      ),
    code3: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema : schema.required('Code is required')
      ),
    code4: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema : schema.required('Code is required')
      ),
    code5: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema : schema.required('Code is required')
      ),
    code6: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema : schema.required('Code is required')
      ),
    otp: yup
      .string()
      .when('$useBackupCode', (useBackupCode, schema) =>
        useBackupCode ? schema.required('Please enter code') : schema
      ),
  })

  const defaultValues = {
    otp: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  }

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(verifyCodeSchema),
    defaultValues,
    context: { useBackupCode },
  })

  const {
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
  } = methods

  const navigate = useNavigate()
  const { isProcessing, verifyOTP, MFA } = useAuth()
  const [errorResp, setErrorResp] = useState('')
  const { recaptchaVerify } = useReacaptcha('login')

  const onSubmit = async (data: FormValuesProps) => {
    const token = await recaptchaVerify()
    const {
      otp,
      code1 = '',
      code2 = '',
      code3 = '',
      code4 = '',
      code5 = '',
      code6 = '',
    } = data
    const code: any = useBackupCode
      ? otp
      : code1 + code2 + code3 + code4 + code5 + code6
    if (token.length) {
      try {
        await verifyOTP(code, token, useBackupCode)
      } catch (e: any) {
        useBackupCode && setError('otp', { type: 'custom', message: e.message })
        setError('code1', { type: 'custom', message: '' })
        setError('code2', { type: 'custom', message: '' })
        setError('code3', { type: 'custom', message: '' })
        setError('code4', { type: 'custom', message: '' })
        setError('code5', { type: 'custom', message: '' })
        setError('code6', { type: 'custom', message: '' })
        !useBackupCode && setErrorResp(e.message)
        setValue('code1', '')
        setValue('code2', '')
        setValue('code3', '')
        setValue('code4', '')
        setValue('code5', '')
        setValue('code6', '')
        setValue('otp', '')
      }
    }
  }

  useEffect(() => {
    if (!MFA || !MFA.mfaHash) {
      navigate('/auth/login')
    }
  }, [MFA])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // flex: '1 1 0%',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ mb: 2 }}>
        {/* <WhiteLabelLogo /> */}
        <h2
          style={{
            marginTop: '16px',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '-0.025em',
            textAlign: 'center',
            color: '#111827',
          }}
        >
          Two-Factor authentication
        </h2>
        <Box
          sx={{
            marginTop: '8px',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#6B7280',
          }}
        >
          {useBackupCode
            ? 'Enter the 6-digit backup code saved by you to confirm your action.'
            : 'Enter the 6-digit code generated by your app to confirm your action.'}{' '}
          <p>(Do not refresh this page)</p>
        </Box>
      </Box>

      <Box
        sx={
          {
            // marginTop: '10px',
          }
        }
      >
        {/* <Box
          sx={{
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '48px',
            paddingBottom: '48px',
            backgroundColor: '#ffffff',
            boxShadow:
              '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          }}
        > */}
        <Card elevation={6} sx={{ py: 5 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction={'column'}
              sx={{
                mx: 'auto',
              }}
            >
              {/* <RHFTextField
                sx={{ width: '49%' }}
                name="firstName"
                label="First Name"
              /> */}
              {useBackupCode ? (
                <Box
                  sx={{
                    display: 'flex',
                    width: '80%',
                    flexDirection: 'column',
                    mx: 'auto',
                    mb: '15px',
                  }}
                >
                  <RHFTextField
                    name="otp"
                    placeholder="Backup Code"
                    label={`Enter backup code`}
                    autoFocus={true}
                    variant="outlined"
                  />
                </Box>
              ) : (
                <Box>
                  <Stack
                    direction="row"
                    sx={{ mb: 0 }}
                    spacing={2}
                    justifyContent="center"
                  >
                    {Object.keys(defaultValues)
                      .filter((name) => name != 'otp')
                      .map((name, index) => (
                        <RHFOTPInput
                          key={name}
                          name={`code${index + 1}` as ValueNames}
                          enableFocus={index === 0}
                          setErrorResp={setErrorResp}
                        />
                      ))}
                  </Stack>

                  {(errorResp ||
                    errors.code1 ||
                    errors.code2 ||
                    errors.code3 ||
                    errors.code4 ||
                    errors.code5 ||
                    errors.code6) && (
                    <FormHelperText error sx={{ px: 8 }}>
                      {errorResp || 'Code is required'}
                    </FormHelperText>
                  )}
                </Box>
              )}

              <LoadingButton
                // fullWidth
                // onClick={handlesubmit}
                sx={{ width: '80%', mx: 'auto', mt: 3 }}
                variant="contained"
                type="submit"
                loading={isProcessing}
              >
                Verify
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>

        <Box sx={{ mt: '20px' }}>
          <Typography
            sx={{
              marginTop: '40px',
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: 'center',
              color: '#6B7280',
            }}
          >
            {useBackupCode ? `Found your device?` : `Can't find your device?`}{' '}
            <a
              href="#!"
              onClick={() => setUseBackupCode(!useBackupCode)}
              style={{ fontWeight: 600 }}
            >
              <span style={{ color: '#212B36' }}>
                {useBackupCode ? 'Use OTP' : 'Use backup code.'}
              </span>
            </a>
          </Typography>
          <Typography
            sx={{
              marginTop: '8px',
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: 'center',
              color: '#6B7280',
            }}
          >
            Not sure about anything?{' '}
            <a href="/auth/login" style={{ fontWeight: 600 }}>
              <span style={{ color: '#212B36' }}>Return to login</span>
            </a>
          </Typography>
        </Box>
      </Box>
      {/* </Box> */}
    </Box>
  )
}

export default VerifyOTP
