// AddEndletFilterForm.jsx
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider } from 'src/components/hook-form'
import {
  Box,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  IconButton,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import FormErrors from './FormErrors'
const formSchema = {
  label: Yup.string().nullable().required('filter is required'),
  operator: Yup.string().nullable().required('Operator is required'),
  value: Yup.string().required('Value is required'),
}

const filterSchema = Yup.object().shape({
  test: Yup.array().of(Yup.object().shape(formSchema)),
})
const filters = [
  { key: 'endlet_name', value: 'Endlet' },
  { key: 'status', value: 'Status' },
  { key: 'service_type', value: 'Service type' },
  { key: 'tags', value: 'Tags' },
  { key: 'location_name', value: 'Location' },
]
const operator = [
  { key: 'is', value: 'is' },
  { key: 'is_not', value: 'is not' },
  { key: 'starts_with', value: 'starts with' },
  { key: 'ends_with', value: 'ends with' },
  { key: 'contains', value: 'contains' },
  { key: 'does_not_contain', value: 'does not contain' },
  { key: 'like', value: 'like' },
  { key: 'not_like', value: 'not like' },
]
const status_options = ['Pending', 'Saved', 'Up', 'Down']

interface Props {
  onSubmit: any
  handleClose: any
  reset: any
}
const EndletMapFilter = ({ onSubmit, handleClose, reset }: Props) => {
  const [isRegExPattern, setIsRegExPattern] = useState(false)
  const [isValidRegEx, setIsValidRegEx] = useState(true)

  const methods = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      test: [{ label: 'endlet_name', operator: 'is', value: '' }],
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
  } = methods

  const { test } = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'test',
  })

  const handleChange = (index: number) => {
    test[index].operator = ''
    test[index].value = ''
  }

  const handleOpChange = (index: number) => {
    const regexPattern = /[.*+?^${}()|[\]\\]/g
    if (!regexPattern.test(test[index].value)) test[index].value = ''
  }

  const handleValChange = (index: number, input: string) => {
    const regexPattern = /[.*+?^${}()|[\]\\]/g

    if (regexPattern.test(input)) {
      setIsRegExPattern(true)
      test[index].operator = ''
      // setIsValidRegEx(true)
    } else setIsRegExPattern(false)
  }

  const handleDelete = (index: number) => {
    remove(index)
  }
  function isRegexPattern(input: any) {
    try {
      new RegExp(input)
      return true // Valid regex
    } catch (error) {
      return false // Not a valid regex
    }
  }

  console.log(
    '---------------------------------------------------------->',
    isRegExPattern
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: '100%', minHeight: '150px' }}>
        <Box>
          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Grid container spacing={2} py={1}>
                    <Grid item xs={4}>
                      <Controller
                        defaultValue={`${item.label}`}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            id="combo-box-demo"
                            options={filters}
                            getOptionLabel={(option) => option.value}
                            defaultValue={filters.find(
                              (fltr) => fltr.key === item.label
                            )}
                            onChange={(event, values) => (
                              onChange(values?.key), handleChange(index)
                            )}
                            ListboxProps={{
                              style: { maxHeight: '300px', overflow: 'auto' },
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Filter" />
                            )}
                          />
                        )}
                        control={control}
                        {...register(`test.${index}.label`)}
                      />

                      <FormErrors errors={errors} field="label" index={index} />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            // disablePortal
                            id="combo-box-demo"
                            value={{
                              key: test[index].operator,
                              value:
                                operator.find(
                                  (op) => op.key === test[index].operator
                                )?.value || '',
                            }}
                            options={
                              test[index].label === 'status' ||
                              test[index].label === 'service_type'
                                ? operator.slice(0, 2)
                                : isRegExPattern
                                ? [
                                    { key: 'like', value: 'like' },
                                    { key: 'is_not', value: 'is not' },
                                  ]
                                : operator.filter((op) => op.key !== 'like')
                            }
                            getOptionLabel={(option) => option.value}
                            defaultValue={operator.find(
                              (op) => op.key === item.operator
                            )}
                            onChange={(event, values) => (
                              onChange(values?.key), handleOpChange(index)
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Operator"
                                defaultValue={`${item.operator}`}
                              />
                            )}
                          />
                        )}
                        control={control}
                        {...register(`test.${index}.operator`)}
                      />
                      <FormErrors errors={errors} field="operator" index={index} />
                    </Grid>
                    <Grid item xs={4} sx={{ overflow: 'auto' }}>
                      {test[index].label === 'status' &&
                      (test[index].operator === 'is' ||
                        test[index].operator === 'is_not') ? (
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              // disablePortal
                              id="combo-box-demo"
                              options={status_options}
                              getOptionLabel={(option) => option}
                              defaultValue={status_options.find(
                                (status) => status === item.value
                              )}
                              onChange={(event, values) => onChange(values)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select status"
                                  defaultValue={`${item.value}`}
                                />
                              )}
                            />
                          )}
                          control={control}
                          {...register(`test.${index}.value`)}
                        />
                      ) : (
                        <Controller
                          defaultValue={`${item.value}`}
                          render={({ field }) => {
                            return (
                              <TextField
                                name={field.name}
                                value={test[index].value || ''}
                                onChange={(e) => (
                                  field.onChange(e),
                                  handleValChange(index, e.target.value)
                                )}
                                fullWidth
                                autoComplete="off"
                              />
                            )
                          }}
                          {...register(`test.${index}.value`)}
                        ></Controller>
                      )}
                      <FormErrors errors={errors} field="value" index={index} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Stack
                    sx={{ mt: 2 }}
                    direction={'row'}
                    spacing={1}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    {index !== fields.length - 1 ? (
                      ''
                    ) : (
                      <IconButton
                        hidden={index !== fields.length - 1}
                        color="primary"
                        aria-label="Add More"
                        onClick={() =>
                          append({ label: '', operator: '', value: '' })
                        }
                      >
                        <AddIcon sx={{ cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    <IconButton
                      disabled={fields.length === 1}
                      color="error"
                      aria-label="Remove"
                      onClick={() => {
                        handleDelete(index)
                      }}
                    >
                      <RemoveIcon sx={{ cursor: 'pointer' }} />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Stack direction={'row'} spacing={1} sx={{ float: 'right', pt: 2 }}>
        <Button
          onClick={handleClose}
          variant="text"
          sx={{ color: 'text.secondary' }}
        >
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained">
          Add
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}

export default EndletMapFilter
