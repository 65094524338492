import {
  Box,
  CircularProgress,
  Link,
  Badge,
  Stack,
  Typography,
  useTheme,
  AccordionSummary,
  Divider,
} from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { PATH_SERVICES } from 'src/routes/paths'
import Label from 'src/components/Label'

function MultipleEndletsLink({ endlets }: any) {
  const { palette } = useTheme()

  return (
    <>
      {endlets.map((endlet: any, index: any) => (
        <Stack spacing={1} sx={{ mt: index === 0 ? 0 : 2, width: '100%' }}>
          <Box>
            <Typography sx={{ color: palette.info.main, margin: 0 }}>
              <Link
                component={RouterLink}
                to={PATH_SERVICES.endlets.endletDetails(
                  endlet.endletId,
                  'configuration'
                )}
                target="_blank"
                sx={{ margin: '0 !important' }}
              >
                {endlet.endletName}
              </Link>{' '}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '13px' }}>
              {`${endlet?.ipRegionLookup?.city}, ${endlet.ipRegionLookup.region_name}`}
            </Typography>
          </Box>

          {/* <Label>Ip Address: </Label> */}
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="body2"
                fontWeight={900}
                sx={{ pr: 1, fontSize: '13px' }}
              >
                IP Address:{' '}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '13px' }}>
                {endlet.endletIp}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 900, fontSize: '13px', pr: 1 }}
              >
                Lat:{' '}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '13px' }}>
                {endlet?.ipRegionLookup?.latitude?.toFixed(4)}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 900, pr: 1, fontSize: '13px' }}
              >
                Long:{' '}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '13px' }}>
                {endlet?.ipRegionLookup?.longitude?.toFixed(4)}
              </Typography>
            </Box>
            <Label
              variant={palette.mode === 'light' ? 'ghost' : 'filled'}
              color={endlet.status === 'Up' ? 'success' : 'warning'}
              sx={{
                textTransform: 'none',
                my: 1,
                py: 1,
                maxWidth: 'fit-content',
                fontSize: '13px',
              }}
            >
              {endlet.status}
            </Label>
          </Box>

          <Divider sx={{ borderColor: 'black' }} />
        </Stack>
      ))}
    </>
  )
}

export default MultipleEndletsLink
