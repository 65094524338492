import React, { useContext, useEffect, useState } from 'react'
import { APIProvider, Map, Pin } from '@vis.gl/react-google-maps'
import MarkerWithInfoWindow from './MarkerWithInfoWindow'
import endletUp from 'src/assets/endletUp.svg'
import endletDown from 'src/assets/endletDown.svg'
import endletInfo from 'src/assets/endletInfo.svg'
import endletWarning from 'src/assets/endletWarning.svg'
import { post } from 'src/utils/httpMethods'
import useAuth from 'src/hooks/useAuth'
import { TenantsContext } from 'src/contexts/TenantsContext'
import EndletLink from './EndletLink'
import MultipleEndletsLink from './MultipleEndletsLink'
import { CircularProgress } from '@mui/material'

const endletStatusMapping = {
  Up: '#2db800',
  Down: '#a83434',
  Pending: '#ffa200',
  Saved: '#4271ff',
}

interface Props {
  filterItems: any
}

function GoogleMap({ filterItems }: Props) {
  const { organization } = useAuth()
  const { selectedTenant } = useContext(TenantsContext)!
  // const position = { lat: 64.85599578876611, lng: -147.83363628361917 }
  const position = { lat: 25.6999972, lng: 20 }

  const [loading, setLoading] = useState(true)
  //   state variable to store endlet location (latitude longitude from API)
  const [endletLatLong, setEndletLatLong] = useState<any>()

  const iconsMapping = {
    up: endletUp,
    down: endletDown,
    info: endletInfo,
    warning: endletWarning,
  }

  useEffect(() => {
    setLoading(true)
    const data = filterItems.length ? { filter: [...filterItems] } : {}
    const fetchLongLat = () => {
      post(
        organization?.accessTier === 'Customer'
          ? `/endlets/location`
          : `/endlets/location/${selectedTenant}`,
        data
      )
        .then((res: any) => {
          setEndletLatLong(res)
          setLoading(false)
        })
        .catch((e) => {
          console.log(e)
          setLoading(false)
        })
    }
    fetchLongLat()
  }, [selectedTenant, filterItems])

  const pinSvgString = `<svg width="56" height="56" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#fff;stroke:;stroke-miterlimit:10;stroke-width:1.92px;}</style></defs><g id="roll_brush" data-name="roll brush"><circle class="cls-1" cx="15.8" cy="14.91" r="6.71"/><circle class="cls-1" cx="8.13" cy="14.91" r="6.71"/><circle class="cls-1" cx="11.96" cy="8.19" r="6.71"/></g></svg>`

  return (
    <div
      style={{
        width: '100%',
        height: '70vh',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <CircularProgress size={50} />
      ) : (
        <APIProvider apiKey={'AIzaSyBRiC3cykZkjPmW4QD-khxKMODZ-xSCKYE'}>
          <Map
            defaultCenter={position}
            defaultZoom={2}
            mapId={'d006a5fbcc784f'}
          >
            {endletLatLong &&
              endletLatLong.map((loc: any) =>
                Array.isArray(loc) ? (
                  <MarkerWithInfoWindow
                    position={{
                      lat: parseInt(loc[0]?.ipRegionLookup?.latitude),
                      lng: parseInt(loc[0]?.ipRegionLookup.longitude),
                    }}
                    pin={
                      <>
                        <Pin
                          background={'#5a37c3'}
                          glyphColor={'#fff'}
                          borderColor={'#774181'}
                          glyph={
                            new DOMParser().parseFromString(
                              pinSvgString,
                              'image/svg+xml'
                            ).documentElement
                          }
                          scale={1.2}
                        />
                      </>
                    }
                  >
                    <MultipleEndletsLink endlets={loc} />
                  </MarkerWithInfoWindow>
                ) : loc.ipRegionLookup.latitude ? (
                  <MarkerWithInfoWindow
                    position={{
                      lat: parseInt(loc.ipRegionLookup.latitude),
                      lng: parseInt(loc.ipRegionLookup.longitude),
                    }}
                    pin={
                      <>
                        <Pin
                          background={
                            loc.status
                              ? endletStatusMapping[
                                  loc.status as keyof typeof endletStatusMapping
                                ]
                              : '#4271ff'
                          }
                          glyphColor={'#fff'}
                          borderColor={'#fff'}
                        />
                      </>
                    }
                  >
                    <EndletLink endlet={loc} />
                  </MarkerWithInfoWindow>
                ) : (
                  <></>
                )
              )}
          </Map>
        </APIProvider>
      )}
    </div>
  )
}

export default GoogleMap
