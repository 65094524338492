import {
  Box,
  CircularProgress,
  Link,
  Badge,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom'
import { PATH_SERVICES } from 'src/routes/paths'
import Label from 'src/components/Label'

function EndletLink({
  endlet: {
    endletId,
    endletIp,
    endletName,
    ipRegionLookup: { latitude, longitude, city, region_name },
    status,
  },
}: any) {
  const { palette } = useTheme()

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Box>
        <Typography sx={{ color: palette.info.main, margin: 0 }}>
          <Link
            component={RouterLink}
            to={PATH_SERVICES.endlets.endletDetails(endletId, 'configuration')}
            target="_blank"
            sx={{ margin: '0 !important' }}
          >
            {endletName}
          </Link>{' '}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '13px' }}>
          {`${city}, ${region_name}`}
        </Typography>
      </Box>

      {/* <Label>Ip Address: </Label> */}
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body2"
            fontWeight={900}
            sx={{ pr: 1, fontSize: '13px' }}
          >
            IP Address:{' '}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '13px' }}>
            {endletIp}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 900, fontSize: '13px', pr: 1 }}
          >
            Lat:{' '}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '13px' }}>
            {latitude?.toFixed(4)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 900, pr: 1, fontSize: '13px' }}
          >
            Long:{' '}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '13px' }}>
            {longitude?.toFixed(4)}
          </Typography>
        </Box>
        <Label
          variant={palette.mode === 'light' ? 'ghost' : 'filled'}
          color={status === 'Up' ? 'success' : 'warning'}
          sx={{
            textTransform: 'none',
            my: 1,
            py: 1,
            maxWidth: 'fit-content',
            fontSize: '13px',
          }}
        >
          {status}
        </Label>
      </Box>
    </Stack>
  )
}

export default EndletLink
