import React, { useContext, useEffect, useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import 'react-dates/lib/css/_datepicker.css'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import BandwidthBytes from './ObservabilityGraphs/BandwidthBytes'
import BandwidthPackets from './ObservabilityGraphs/BandwidthPackets'
import InterfaceStatusChange from './ObservabilityGraphs/InterfaceStatusChange'
import InterfaceError from './ObservabilityGraphs/InterfaceError'
import { get } from 'src/utils/httpMethods'
import { InterfacesContext } from './EndletDetails'
import moment from 'moment'
import DateRangeSelector from './ObservabilityGraphs/DateRangeSelector'

const time_intervals = [
  { key: '5m', value: 'Last 5 minutes' },
  { key: '15m', value: 'Last 15 minutes' },
  { key: '30m', value: 'Last 30 minutes' },
  { key: '1h', value: 'Last 1 hour' },
  { key: '3h', value: 'Last 3 hours' },
  { key: '6h', value: 'Last 6 hours' },
  { key: '12h', value: 'Last 12 hours' },
  { key: '24h', value: 'Last 24 hours' },
  { key: '2d', value: 'Last 2 days' },
  { key: '7d', value: 'Last 7 days' },
  { key: '30d', value: 'Last 30 days' },
  { key: '90d', value: 'Last 90 days' },
  { key: '6mn', value: 'Last 6 months' },
  { key: '1y', value: 'Last 1 year' },
  { key: '2y', value: 'Last 2 years' },
  { key: '5y', value: 'Last 5 years' },
  { key: 'yesterday', value: 'Yesterday' },
  { key: 'day_before_yes', value: 'Day before yesterday' },
  { key: 'last_week', value: 'This day last week' },
  { key: 'prev_week', value: 'Previous week' },
  { key: 'prev_month', value: 'Previous month' },
  { key: 'prev_f_quar', value: 'Previous fiscal quarter' },
  { key: 'prev_f_year', value: 'Previous fiscal year' },
  { key: 'today', value: 'Today' },
  { key: 'today_so_far', value: 'Today so far' },
  { key: 'this_week', value: 'This week' },
  { key: 'this_week_so_far', value: 'This week so far' },
  { key: 'this_mon', value: 'This month' },
  { key: 'this_mon_so_far', value: 'This month so far' },
  { key: 'this_year', value: 'This year' },
  { key: 'this_year_so_far', value: 'This year so far' },
  { key: 'this_fiscal_quar_so_far', value: 'This fiscal quarter so far' },
  { key: 'this_fiscal_quar', value: 'This fiscal quarter' },
  { key: 'this_fiscal_year_so_far', value: 'This fiscal year so far' },
  { key: 'this_fiscal_year', value: 'This fiscal year' },
]

function InterfaceGraphs() {
  const interfaces = useContext(InterfacesContext)!
  const metricNames = [
    { key: 'node_network_receive_bytes_total', value: 'receivedBytes' },
    { key: 'node_network_transmit_bytes_total', value: 'transmitBytes' },
    { key: 'node_network_receive_packets_total', value: 'receivedPackets' },
    { key: 'node_network_transmit_packets_total', value: 'transmitPackets' },
    { key: 'node_network_receive_errs_total', value: 'receivedErrors' },
    { key: 'node_network_transmit_errs_total', value: 'transmitErrors' },
    { key: 'node_network_receive_drop_total', value: 'receivedDrops' },
    { key: 'node_network_transmit_drop_total', value: 'transmitDrops' },
    { key: 'node_network_transmit_colls_total', value: 'receivedColls' },
    { key: 'node_network_transmit_colls_total', value: 'transmitColls' },
  ]
  const [metricData, setMetricData] = useState({
    receivedBytes: [],
    transmitBytes: [],
    receivedPackets: [],
    transmitPackets: [],
    receivedErrors: [],
    transmitErrors: [],
    receivedDrops: [],
    transmitDrops: [],
    receivedColls: [],
    transmitColls: [],
  })
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<any>('15m')
  const [fromTimestamp, setFromTimestamp] = useState<any>('')
  const [toTimestamp, setToTimestamp] = useState<any>('')
  const [APIResponse, setAPIResponse] = useState<any>({})
  const [dateRange, setDateRange] = useState<any>({
    toDate: null,
    fromDate: null,
  })
  const [selectedDevices, setSelectedDevices] = useState<any>(
    interfaces?.map((each) => each.name) || []
  )
  const handleChange = (event: any, selected: any) => {
    console.log(selected)
    setSelectedTimeInterval(selected.key)
  }

  const handleFromDateChange = (newValue: any) => {
    setDateRange((prev: any) => ({
      ...prev,
      fromDate: newValue,
    }))
  }

  const handleToDateChange = (newValue: any) => {
    setDateRange((prev: any) => ({
      ...prev,
      toDate: newValue,
    }))
  }
  const getData = () => {
    get(
      `https://us-central1-packetsdev.cloudfunctions.net/portal_backend/endlets/observability/metrics?endletID=lightsail-instance-1&query=rate(node_network_transmit_bytes_total%7Bdevice%3D%27eth0%27%7D%5B${selectedTimeInterval}%5D&start=${fromTimestamp}&end=${toTimestamp}&step=300`
    )
      .then((response: any) => {
        setAPIResponse(response.data)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  useEffect(() => {
    const calculateDefaultDateRange = () => {
      const currentDate = new Date()
      let fromDate: any
      let toDate: any = currentDate

      fromDate = new Date(currentDate.getTime() - 15 * 60 * 1000) // Subtract 15 minutes
      // console.log(fromDate)
      // Update the state with the calculated values
      setFromTimestamp(Math.floor(fromDate.getTime() / 1000))
      setToTimestamp(Math.floor(toDate.getTime() / 1000))
      setDateRange({
        fromDate: fromDate,
        toDate: toDate,
      })
    }

    calculateDefaultDateRange()
    // getData()
  }, [])

  useEffect(() => {
    if (fromTimestamp && toTimestamp) getData()
  }, [fromTimestamp, toTimestamp])

  useEffect(() => {
    if (dateRange.fromDate && dateRange.toDate) {
      if (moment.isMoment(dateRange.fromDate)) {
        setFromTimestamp(Math.floor(dateRange.fromDate._d.getTime() / 1000))
        setSelectedTimeInterval('custom')
      } else setFromTimestamp(Math.floor(dateRange.fromDate.getTime() / 1000))

      if (moment.isMoment(dateRange.toDate)) {
        setFromTimestamp(Math.floor(dateRange.toDate._d.getTime() / 1000))
        setSelectedTimeInterval('custom')
      } else {
        setToTimestamp(Math.floor(dateRange.toDate.getTime() / 1000))
      }
    }
  }, [dateRange])

  const filterMetrics = (metricName: string) => {
    return APIResponse?.result
      ?.filter((res: any) => res.metric.__name__ === metricName)
      ?.filter((item: any) =>
        interfaces?.some((obj) => obj.name === item.metric.device)
      )
  }
  useEffect(() => {
    const newData: any = {}
    metricNames.forEach((metricName) => {
      newData[metricName.value] = filterMetrics(metricName.key)
    })
    setMetricData(newData)
  }, [APIResponse, interfaces])

  const handleDeviceSelect = (name: any) => (e: any) => {
    if (e.target.checked) {
      setSelectedDevices([...selectedDevices, name])
    } else {
      setSelectedDevices(selectedDevices.filter((value: any) => value !== name))
    }
  }
  useEffect(() => {
    const devices: any = []
    interfaces?.map((each) => devices.push(each.name))
    setSelectedDevices(devices)
  }, [interfaces])

  //fiscal year calculations

  return (
    <div>
      <DateRangeSelector
        selectedTimeInterval={selectedTimeInterval}
        setDateRange={setDateRange}
      />
      <Stack sx={{ pt: 1 }} direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From"
            value={dateRange.fromDate || null}
            inputFormat="MM/DD/YYYY HH:mm:ss"
            onChange={handleFromDateChange}
            renderInput={(params) => (
              <TextField sx={{ width: 250 }} size="small" {...params} />
            )}
          />
          <DatePicker
            label="To"
            value={dateRange.toDate || null}
            inputFormat="MM/DD/YYYY HH:mm:ss"
            onChange={handleToDateChange}
            renderInput={(params) => (
              <TextField sx={{ width: 250 }} size="small" {...params} />
            )}
          />
        </LocalizationProvider>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={time_intervals}
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.value}
          value={
            time_intervals.find((option) => option.key === selectedTimeInterval) || {
              key: 'custom',
              value: 'Custom',
            }
          }
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Select Time Interval" />
          )}
        />

        <FormGroup>
          <Grid direction="row" ml={3}>
            {interfaces?.map((each) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={selectedDevices.includes(each.name)}
                      onChange={handleDeviceSelect(each.name)}
                    />
                  }
                  label={each.name}
                />
              </>
            ))}
          </Grid>
        </FormGroup>
      </Stack>
      <BandwidthBytes
        graphData={{
          receivedBytes: metricData.receivedBytes,
          transmitBytes: metricData.transmitBytes,
        }}
        selectedDevices={selectedDevices}
      />
      <BandwidthPackets
        graphData={{
          receivedPackets: metricData.receivedPackets,
          transmitPackets: metricData.transmitPackets,
        }}
        selectedDevices={selectedDevices}
      />
      <Typography variant="subtitle1" sx={{ py: 1 }}>
        Interface Status Changes
      </Typography>
      <InterfaceStatusChange
        data={{
          dateRange: dateRange,
          setDateRange: setDateRange,
          selectedTimeInterval: selectedTimeInterval,
        }}
      />

      <InterfaceError
        metricData={metricData}
        errorProps={{
          transmitErrors: metricData.transmitErrors,
          receivedErrors: metricData.receivedErrors,
        }}
        dropProps={{
          transmitDrops: metricData.transmitDrops,
          receivedDrops: metricData.receivedDrops,
        }}
        collProps={{
          transmitColls: metricData.transmitColls,
          receivedColls: metricData.receivedColls,
        }}
        selectedDevices={selectedDevices}
      />
    </div>
  )
}

export default InterfaceGraphs
