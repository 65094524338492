import React, { useState } from 'react'
import EndletsMap from './EndletsMap'
import { Card } from '@mui/material'
import EndletMapFilter from './EndletMapFilter'
import GoogleMap from '../GoogleMaps/GoogleMap'

function CytoscapeTabView() {
  const [filterItems, setFilterItems] = useState<any>(
    JSON.parse(localStorage.getItem('map_filters') || '[]')
  )

  return (
    <>
      {' '}
      <Card>
        <EndletMapFilter filterProps={{ filterItems, setFilterItems }} />
        {/* <EndletsMap filterItems={filterItems} /> */}
        <GoogleMap filterItems={filterItems} />
      </Card>
    </>
  )
  // return <div> Map element here </div>
}

export default CytoscapeTabView
