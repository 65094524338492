import React, { useState } from 'react'
import { Stack } from '@mui/system'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider } from 'src/components/hook-form'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'

// @mui
import {
  Box,
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  IconButton,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import FormErrors from './FormErrors'
import FilterInput from './FilterInput'
const formSchema = {
  label: Yup.string().nullable().required('filter is required'),
  operator: Yup.string().nullable().required('Operator is required'),
  value: Yup.string().required('Value is required'),
  sub_type: Yup.string(),
  // sub_type: Yup.string().when('label', {
  //   is: 'service_type',
  //   then: Yup.string().required('Service Type is required'),
  // }),
}

const filterSchema = Yup.object().shape({
  test: Yup.array().of(Yup.object().shape(formSchema)),
})
const filters = [
  { key: 'service_name', value: 'Service Name' },
  { key: 'status', value: 'Status' },
  { key: 'service_type', value: 'Service Type' },
  { key: 'tags', value: 'Tags' },
]

const operator = [
  { key: 'is', value: 'is' },
  { key: 'is_not', value: 'is not' },
  { key: 'starts_with', value: 'starts with' },
  { key: 'ends_with', value: 'ends with' },
  { key: 'contains', value: 'contains' },
  { key: 'does_not_contain', value: 'does not contain' },
  { key: 'like', value: 'like' },
  { key: 'not_like', value: 'not like' },
]

const status_options = [
  'Service Configured',
  'Endlets Added',
  'Service Deployed',
  'Up',
]
const service_type_options = ['vWAN']
const service_sub_type_options = [
  'All',
  'Point To Point',
  'Full Mesh',
  'Hub And Spoke',
]

interface Props {
  onSubmit: any
  handleClose: any
  reset: any
}
const ServiceFilterForm = ({ onSubmit, handleClose, reset }: Props) => {
  const [isRegExPattern, setIsRegExPattern] = useState(false)

  const methods = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      test: [{ label: 'service_name', operator: 'is', value: '', sub_type: '' }],
    },
  })
  const {
    register,
    control,
    handleSubmit,
    watch,

    formState: { errors },
  } = methods
  const { test } = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'test',
  })

  const handleAddMore = () => {
    append({ label: '', operator: '', value: '', sub_type: '' })
  }
  const handleDelete = (index: number) => {
    remove(index)
  }
  const handleChange = (index: any) => {
    test[index].operator = ''
    test[index].value = ''
  }
  const handleOpChange = (index: any, event: any) => {
    if (event.target.value === 'status' || event.target.value === 'service_type')
      test[index].sub_type = ''
  }
  const handleSubType = (index: any) => {
    test[index].sub_type = ''
  }
  function checkForSubType(index: number) {
    return (
      (test[index].label === 'service_type' &&
        !service_type_options.includes(test[index].value)) ||
      test[index].label !== 'service_type'
    )
  }
  const handleValChange = (index: number, input: string) => {
    const regexPattern = /[.*+?^${}()|[\]\\]/g

    if (regexPattern.test(input)) {
      setIsRegExPattern(true)
      test[index].operator = ''
    } else setIsRegExPattern(false)
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ width: '100%', minHeight: '150px' }}>
        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Grid container spacing={2} py={1}>
                  <Grid item xs={checkForSubType(index) ? 4 : 3}>
                    <Controller
                      defaultValue={`${item.label}`}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          // disablePortal
                          id="combo-box-demo"
                          options={filters}
                          getOptionLabel={(option) => option.value}
                          defaultValue={filters.find(
                            (fltr) => fltr.key === item.label
                          )}
                          onChange={(event, values) => (
                            onChange(values?.key), handleChange(index)
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Filter" />
                          )}
                        />
                      )}
                      control={control}
                      {...register(`test.${index}.label`)}
                    />
                    <FormErrors errors={errors} field="label" index={index} />
                  </Grid>
                  <Grid item xs={checkForSubType(index) ? 4 : 3}>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          // disablePortal
                          id="combo-box-demo"
                          value={{
                            key: test[index].operator,
                            value:
                              operator.find((op) => op.key === test[index].operator)
                                ?.value || '',
                          }}
                          options={
                            test[index].label === 'status' ||
                            test[index].label === 'service_type'
                              ? operator.slice(0, 2)
                              : isRegExPattern
                              ? [
                                  { key: 'like', value: 'like' },
                                  { key: 'is_not', value: 'is not' },
                                ]
                              : operator.filter((op) => op.key !== 'like')
                          }
                          getOptionLabel={(option) => option.value}
                          defaultValue={operator.find(
                            (op) => op.key === item.operator
                          )}
                          onChange={(event, values) => (
                            onChange(values?.key), handleOpChange(index, event)
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Operator"
                              defaultValue={`${item.operator}`}
                            />
                          )}
                        />
                      )}
                      control={control}
                      {...register(`test.${index}.operator`)}
                    />
                    <FormErrors errors={errors} field="operator" index={index} />
                  </Grid>
                  <Grid item xs={checkForSubType(index) ? 4 : 3}>
                    {test[index].label === 'status' &&
                    (test[index].operator === 'is' ||
                      test[index].operator === 'is_not') ? (
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            // disablePortal
                            id="combo-box-demo"
                            options={status_options}
                            getOptionLabel={(option) => option}
                            defaultValue={status_options.find(
                              (status) => status === item.value
                            )}
                            onChange={(event, values) => onChange(values)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Value"
                                defaultValue={`${item.value}`}
                              />
                            )}
                          />
                        )}
                        control={control}
                        {...register(`test.${index}.value`)}
                      />
                    ) : test[index].label === 'service_type' &&
                      (test[index].operator === 'is' ||
                        test[index].operator === 'is_not') ? (
                      <>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={service_type_options}
                              getOptionLabel={(option) => option}
                              defaultValue={service_type_options.find(
                                (type) => type === item.value
                              )}
                              onChange={(event, values) => (
                                onChange(values), handleSubType(index)
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type"
                                  defaultValue={`${item.value}`}
                                />
                              )}
                            />
                          )}
                          control={control}
                          {...register(`test.${index}.value`)}
                        />
                      </>
                    ) : (
                      <Controller
                        defaultValue={`${item.value}`}
                        render={({ field }) => {
                          return (
                            <TextField
                              // label="Value"
                              name={field.name}
                              value={test[index].value || ''}
                              onChange={(e) => (
                                field.onChange(e),
                                handleValChange(index, e.target.value)
                              )}
                              fullWidth
                              autoComplete="off"
                            />
                          )
                        }}
                        {...register(`test.${index}.value`)}
                      ></Controller>
                    )}

                    <FormErrors errors={errors} field="value" index={index} />
                  </Grid>

                  {checkForSubType(index) ? (
                    ''
                  ) : (
                    <Grid item xs={3}>
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            // disablePortal
                            hidden={test[index].label !== 'service_type'}
                            id="combo-box-demo"
                            options={service_sub_type_options}
                            getOptionLabel={(option) => option}
                            defaultValue={
                              test[index].value
                                ? service_sub_type_options.find(
                                    (type) => type === item.sub_type
                                  )
                                : ''
                            }
                            onChange={(event, values) => onChange(values)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  test[index].value
                                    ? `${test[index].value} Type`
                                    : ''
                                }
                                defaultValue={`${item.sub_type}`}
                              />
                            )}
                          />
                        )}
                        control={control}
                        {...register(`test.${index}.sub_type`)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Stack
                  sx={{ mt: 2 }}
                  direction={'row'}
                  spacing={1}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  {index !== fields.length - 1 ? (
                    ''
                  ) : (
                    <IconButton
                      hidden={index !== fields.length - 1}
                      color="primary"
                      aria-label="Add More"
                      onClick={handleAddMore}
                    >
                      <AddIcon sx={{ cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  <IconButton
                    disabled={fields.length === 1}
                    color="error"
                    aria-label="Remove"
                    onClick={() => {
                      handleDelete(index)
                    }}
                  >
                    <RemoveIcon sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Box>
      <Stack direction={'row'} spacing={1} sx={{ float: 'right', pt: 2 }}>
        <Button
          onClick={handleClose}
          variant="text"
          sx={{ color: 'text.secondary' }}
        >
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained">
          Add
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}

export default ServiceFilterForm
