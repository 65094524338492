import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormHelperText,
  FormLabel,
  Input,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { OpenModalProps } from 'src/@types/modalProps'
import AuthenticatorCode from './AuthenticatorCode'
import totpImage from 'src/assets/TOTPgraphic.svg'
import useAuth from 'src/hooks/useAuth'
import MFAHelp from 'src/sections/auth/verify-code/MFAHelp'
import { get, post } from 'src/utils/httpMethods'
import { auth_app } from './MultiFactorAuth'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { DialogTitle } from '@mui/material'
import { DialogContent } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Label from 'src/components/Label'
import { FormProvider, RHFTextField } from 'src/components/hook-form'
import { InputLabel } from '@mui/material'
import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  CheckCircleOutline,
  CheckCircleRounded,
  CheckOutlined,
  Download,
  DownloadOutlined,
  FileDownload,
  FileDownloadOutlined,
} from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { OutlinedInput } from '@mui/material'
import RHFOTPInput from 'src/components/hook-form/RHFOTPInput'

interface Props {
  openProps: OpenModalProps
}

const authAppMethods = [
  {
    label: 'Google Authenticator',
    value: 'Google_Authenticator',
  },
  {
    label: 'Duo (Coming soon)',
    value: 'Duo',
    isDisabled: true,
  },
]

type FormValuesProps = {
  code1: string
  code2: string
  code3: string
  code4: string
  code5: string
  code6: string
}
type ValueNames = 'code1' | 'code2' | 'code3' | 'code4' | 'code5' | 'code6'

function MfaSettings({ openProps: { open, setOpen } }: Props) {
  const { user, logout, UseNewUserData } = useAuth()
  const { refetchUserData } = UseNewUserData()
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const [authApp, setAuthApp] = useState(
    user?.MFA?.authenticatorApp
      ? user?.MFA?.authenticatorApp
      : 'Google_Authenticator'
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [backupCodes, setBackupCodes] = useState([])
  const { changeMfa, setChangeMfa } = useAuth()
  const [qrData, setQrData] = useState({
    url: '',
    secret: '',
  })
  const {
    generateQR,
    mfaStatus: { enabled, authenticatorApp },
  } = useAuth()

  const verifyCodeSchema = yup.object().shape({
    code1: yup.string().required('Code is required'),
    code2: yup.string().required('Code is required'),
    code3: yup.string().required('Code is required'),
    code4: yup.string().required('Code is required'),
    code5: yup.string().required('Code is required'),
    code6: yup.string().required('Code is required'),
  })
  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  }

  const methods = useForm<FormValuesProps>({
    mode: 'all',
    resolver: yupResolver(verifyCodeSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
    watch,
    setValue,
    // formState: { errors },
  } = methods

  const handleClose = () => {
    setOpen(false)
    setIsSuccess(false)
    setMessage('')
    setQrData({
      url: '',
      secret: '',
    })
  }

  const fetchQRAndCode = async () => {
    setIsLoading(true)
    try {
      const data: any = await get(
        `${process.env.REACT_APP_HOST_API_URL}/users/MFA/setup`
      )
      setQrData({ url: data.qr_url, secret: data.secret_code })
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      setSnackbarProps({
        open: true,
        message: error.message,
        severity: 'error',
      })
    }
  }

  useEffect(() => {
    open && fetchQRAndCode()
  }, [open])

  const onSubmit = async (data: FormValuesProps) => {
    setIsConfirming(true)
    setMessage('')

    try {
      const { code1, code2, code3, code4, code5, code6 } = data
      const otp = code1 + code2 + code3 + code4 + code5 + code6
      const result: any = await post(
        `${process.env.REACT_APP_HOST_API_URL}/users/MFA/setup`,
        {
          mfaApp: authApp,
          otp: otp,
          secretCode: qrData.secret,
        }
      )
      setIsConfirming(false)
      setIsSuccess(true)
      setMessage(result.message)
      setBackupCodes(result.backup_codes)
      await refetchUserData()
    } catch (e: any) {
      setError('code1', { type: 'custom', message: '' })
      setError('code2', { type: 'custom', message: '' })
      setError('code3', { type: 'custom', message: '' })
      setError('code4', { type: 'custom', message: '' })
      setError('code5', { type: 'custom', message: '' })
      setError('code6', { type: 'custom', message: '' })
      setValue('code1', '')
      setValue('code2', '')
      setValue('code3', '')
      setValue('code4', '')
      setValue('code5', '')
      setValue('code6', '')
      setIsConfirming(false)
      setMessage(e.message)
      setIsSuccess(false)
    }
  }

  const authAppName = auth_app.find(
    (elem) => elem.value === changeMfa.authenticatorApp
  )

  const DownloadButton = ({ textOutput }: { textOutput: string }) => {
    const file = new Blob([textOutput], { type: 'text/plain' })
    return (
      <a
        download="21Packets.BackupCodes.txt"
        target="_blank"
        rel="noreferrer"
        href={URL.createObjectURL(file)}
        style={{
          textDecoration: 'inherit',
          color: 'inherit',
        }}
      >
        <span style={{ color: '#51D6FF' }}>
          <FileDownloadOutlined className="h-4 inline" /> Download codes
        </span>
      </a>
    )
  }

  const values = watch()

  return (
    <>
      {!isSuccess ? (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll="body"
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <DialogTitle sx={{ p: 0 }}>Multi-Factor Authentication</DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4',
                    fontSize: '14px',
                    lineHeight: '1.5rem',
                    mt: '12px',
                  }}
                >
                  <Typography sx={{ color: 'text.secondary' }}>
                    Multi-factor authentication (MFA) has been enabled on your
                    account. With MFA enabled, you will be asked to provide a
                    one-time password (OTP) in addition to your username and
                    password.
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    You will need an authenticator app installed on your device to
                    proceed.{' '}
                  </Typography>
                </Box>
                <label
                  id="demo-simple-select-label"
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5rem',
                    marginTop: '0.75rem',
                    color: '#111827',
                  }}
                >
                  Select Authenticator
                </label>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    marginTop: '0.5rem',
                  }}
                >
                  <Select
                    displayEmpty
                    fullWidth
                    // label="Age"
                    sx={{ color: '#000' }}
                    // renderValue={authApp}
                    name="authApp"
                    value={authApp}
                    onChange={(e: any) => setAuthApp(e.target.value)}
                  >
                    {authAppMethods.map((app) => (
                      <MenuItem
                        value={app.value}
                        key={app.value}
                        disabled={app.isDisabled}
                      >
                        {app.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box sx={{ lineHeight: '24px', mt: '20px', color: '#4B5563' }}>
                  1. Scan the barcode with your authenticator app :
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    m: '16px',
                  }}
                >
                  <Box
                    sx={{
                      width: '240px',
                      height: '240px',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#fff',
                    }}
                  >
                    {!isLoading ? (
                      <img src={qrData.url} alt="" />
                    ) : (
                      // <LoadingButton
                      //   fullWidth
                      //   // sx={{ backgroundColor: '#fff', color: '#000', }}
                      //   loading={isLoading}
                      //   variant="text"
                      //   type="submit"
                      // />
                      <CircularProgress sx={{ m: 'auto' }} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyItems: 'start',
                      alignSelf: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#4B5563',
                      }}
                    >
                      Or enter this code into the Authentication App:
                    </Typography>
                    <Typography sx={{ mt: '20px', color: '#000', fontSize: '16px' }}>
                      {qrData.secret}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{ marginTop: '12px', lineHeight: '24px', color: '#4B5563' }}
                >
                  2. Enter the OTP generated by your authenticator app:
                </Box>
                <Stack direction="row" sx={{ mt: 1 }} spacing={2} justifyContent="">
                  {Object.keys(values).map((name, index) => (
                    <RHFOTPInput
                      key={name}
                      name={`code${index + 1}` as ValueNames}
                      enableFocus={index === 0}
                      setErrorResp={setMessage}
                    />
                  ))}
                </Stack>
                {(message ||
                  errors.code1?.message ||
                  errors.code2?.message ||
                  errors.code3?.message ||
                  errors.code4?.message ||
                  errors.code5?.message ||
                  errors.code6?.message) && (
                  <FormHelperText error sx={{ px: 0 }}>
                    {message || 'Code is required'}
                  </FormHelperText>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '36px',
                  marginLeft: '8px',
                  justifyContent: 'flex-end',
                }}
                className="flex mt-9 space-x-2 justify-end"
              >
                <Button
                  onClick={handleClose}
                  variant="text"
                  sx={{ color: 'text.secondary' }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isConfirming}
                >
                  Confirm
                </LoadingButton>
              </Box>
            </FormProvider>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} scroll="body" maxWidth="md">
          <DialogContent>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50px',
                  width: '48px',
                  height: '48px',
                  backgroundColor: '#D1FAE5',
                  mx: 'auto',
                }}
              >
                <CheckOutlined
                  sx={{ width: '24px', height: '24px', color: '#059669' }}
                  aria-hidden="true"
                />
              </Box>
              <Box
                sx={{
                  marginTop: '12px',
                  textAlign: 'center',
                  '@media (minWidth: 640px)': { marginTop: '20px' },
                }}
              >
                <DialogTitle>You're all set!</DialogTitle>
                <Box sx={{ mt: '8px' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#6B7280',
                    }}
                  >
                    {message}
                  </Typography>
                </Box>
                <Box sx={{ mt: '8px' }}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                      rowGap: '20px',
                      columnGap: '16px',
                      py: '24px',
                    }}
                  >
                    {backupCodes.map((code) => (
                      <Box sx={{ fontWeight: 500 }}>{code}</Box>
                    ))}
                  </Box>
                </Box>
                <Box sx={{ mt: '8px' }}>
                  <DownloadButton textOutput={backupCodes.join(', ')} />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: '20px',
                '@media (min-width: 640px)': {
                  display: 'grid',
                  marginTop: '24px',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  gridAutoFlow: 'row dense',
                  gap: '12px',
                },
              }}
            >
              <Button
                sx={{
                  color: '#000',
                  fontWeight: 600,
                  border: '0.2px solid #D1D5DB',
                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingLeft: '0.75rem',
                  paddingRight: '0.75rem',
                }}
                onClick={() => logout()}
                variant="text"
              >
                Logout now
              </Button>
              <LoadingButton
                onClick={() => handleClose()}
                variant="contained"
                type="button"
              >
                Stay logged in
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default MfaSettings
