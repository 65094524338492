import React, { useState } from 'react'
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps'

function MarkerWithInfoWindow({ children, position, pin }: any) {
  const [infowindowOpen, setInfowindowOpen] = useState(false)
  const [markerRef, marker] = useAdvancedMarkerRef()
  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen((prev) => !prev)}
        position={position}
        title={'AdvancedMarker that opens an Infowindow when clicked.'}
      >
        {pin}
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={500}
          onCloseClick={() => setInfowindowOpen(false)}
        >
          <div style={{ padding: '2px' }}>{children}</div>
        </InfoWindow>
      )}
    </>
  )
}

export default MarkerWithInfoWindow
