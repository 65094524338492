export const countries: any = {
  Albania: 'January 1 - December 31',
  Algeria: 'January 1 - December 31',
  Andorra: 'January 1 - December 31',
  Argentina: 'January 1 - December 31',
  Australia: 'July 1 - June 30',
  Austria: 'January 1 - December 31',
  Bahrain: 'January 1 - December 31',
  Bangladesh: 'July 1 - June 30',
  Belgium: 'January 1 - December 31',
  Brazil: 'January 1 - December 31',
  Canada: 'April 1 - March 31',
  Chile: 'January 1 - December 31',
  China: 'January 1 - December 31',
  Colombia: 'January 1 - December 31',
  'Costa Rica': 'January 1 - December 31',
  'Czech Republic': 'January 1 - December 31',
  Denmark: 'January 1 - December 31',
  Egypt: 'July 1 - June 30',
  Finland: 'January 1 - December 31',
  France: 'January 1 - December 31',
  Germany: 'January 1 - December 31',
  Greece: 'January 1 - December 31',
  'Hong Kong': 'April 1 - March 31',
  Hungary: 'January 1 - December 31',
  Iceland: 'January 1 - December 31',
  India: 'April 1 - March 31',
  Indonesia: 'January 1 - December 31',
  Iraq: 'January 1 - December 31',
  Ireland: 'January 1 - December 31',
  Israel: 'January 1 - December 31',
  Italy: 'January 1 - December 31',
  Japan: 'April 1 - March 31',
  Jordan: 'January 1 - December 31',
  Kenya: 'July 1 - June 30',
  Kuwait: 'April 1 - March 31',
  Lebanon: 'January 1 - December 31',
  Malaysia: 'January 1 - December 31',
  Mexico: 'January 1 - December 31',
  Netherlands: 'January 1 - December 31',
  'New Zealand': 'July 1 - June 30',
  Nigeria: 'January 1 - December 31',
  Norway: 'January 1 - December 31',
  Oman: 'January 1 - December 31',
  Pakistan: 'July 1 - June 30',
  Peru: 'January 1 - December 31',
  Philippines: 'January 1 - December 31',
  Poland: 'January 1 - December 31',
  Portugal: 'January 1 - December 31',
  Qatar: 'January 1 - December 31',
  Russia: 'January 1 - December 31',
  'Saudi Arabia': 'January 1 - December 31',
  Singapore: 'April 1 - March 31',
  'South Africa': 'April 1 - March 31',
  'South Korea': 'January 1 - December 31',
  Spain: 'January 1 - December 31',
  Sweden: 'January 1 - December 31',
  Switzerland: 'January 1 - December 31',
  Taiwan: 'January 1 - December 31',
  Thailand: 'October 1 - September 30',
  Turkey: 'January 1 - December 31',
  'United Arab Emirates': 'January 1 - December 31',
  'United Kingdom': 'April 1 - March 31',
  'United States of America': 'October 1 - September 30',
  Vietnam: 'January 1 - December 31',
}
