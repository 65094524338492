export const calculateFiscalYearDates = (option: string, country: string) => {
  const monthNumber: any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  }

  let fiscalYearStartDate = null
  let fiscalYearEndDate = null
  const [startStr, endStr] = country?.split(' - ')

  // Determine the start and end months and days
  const [startMonth, startDay] = startStr?.split(' ')
  const [endMonth, endDay] = endStr?.split(' ')
  // Get the current year

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1 // January is 1, February is 2, etc.
  const currentDay = currentDate.getDay() + 1

  const prevYear = currentYear - 1

  switch (option) {
    case 'this_fiscal_year':
      fiscalYearStartDate = new Date(`${startMonth} ${startDay}, ${currentYear}`)
      fiscalYearEndDate = new Date(`${endMonth} ${endDay}, ${currentYear + 1}`)
      break

    case 'this_fiscal_year_so_far':
      if (
        currentMonth < monthNumber[startMonth] ||
        (currentMonth === monthNumber[startMonth] && currentDay < parseInt(startDay))
      ) {
        fiscalYearStartDate = new Date(
          `${startMonth} ${startDay}, ${currentYear - 1}`
        )
      } else {
        fiscalYearStartDate = new Date(`${startMonth} ${startDay}, ${currentYear}`)
      }
      fiscalYearEndDate = new Date()
      break

    case 'prev_f_year':
      fiscalYearStartDate = new Date(`${startMonth} ${startDay}, ${currentYear - 1}`)
      fiscalYearEndDate = new Date(`${endMonth} ${endDay}, ${currentYear}`)
      break

    case 'prev_f_quar':
      fiscalYearStartDate = new Date(`${startMonth} ${startDay}, ${prevYear}`)
      fiscalYearEndDate = new Date(
        prevYear,
        fiscalYearStartDate.getMonth() + 2,
        fiscalYearStartDate.getDate()
      )
      break

    case 'this_fiscal_quar':
      fiscalYearStartDate = new Date(`${startMonth} ${startDay}, ${currentYear}`)
      fiscalYearEndDate = new Date(
        currentYear,
        fiscalYearStartDate.getMonth() + 2,
        fiscalYearStartDate.getDate()
      )
      break
    case 'this_fiscal_quar_so_far':
      if (
        currentMonth < monthNumber[startMonth] ||
        (currentMonth === monthNumber[startMonth] && currentDay < parseInt(startDay))
      ) {
        fiscalYearStartDate = new Date(
          `${startMonth} ${startDay}, ${currentYear - 1}`
        )
      } else {
        fiscalYearStartDate = new Date(`${startMonth} ${startDay}, ${currentYear}`)
      }
      fiscalYearEndDate = new Date()
      break

    default:
      fiscalYearStartDate = null
      fiscalYearEndDate = null
  }

  return {
    start: fiscalYearStartDate,
    end: fiscalYearEndDate,
  }
}
