// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { OutlinedInput, TextField, TextFieldProps } from '@mui/material'
import { useEffect } from 'react'

// ----------------------------------------------------------------------

type IProps = {
  name: string
  setErrorResp: React.Dispatch<React.SetStateAction<string>>
  enableFocus: boolean
  key: any
}

type Props = IProps & TextFieldProps

export default function RHFOTPInput({
  name,
  setErrorResp,
  enableFocus,
  key,
  ...other
}: Props) {
  const {
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext()

  const handlePaste = (event: any) => {
    let data = event.clipboardData.getData('text')

    data = data.split('')
    ;[].forEach.call(
      document.querySelectorAll('.field-code'),
      (node: any, index) => {
        node.value = data[index]

        const fieldIndex = `code${index + 1}`

        setValue(fieldIndex, data[index])
        clearErrors(['code1', 'code2', 'code3', 'code4', 'code5', 'code6'])
        setErrorResp('')
      }
    )

    event.preventDefault()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement
    const { name } = inputElement

    const fieldIndex = name.replace('code', '')

    const fieldIntIndex = Number(fieldIndex)

    if (event.key === 'Backspace') {
      const currentFeild = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement

      const prevFeild = document.querySelector(
        `input[name=code${fieldIntIndex - 1}]`
      )

      if (prevFeild !== null && !currentFeild?.value.length) {
        ;(prevFeild as HTMLElement).focus()
      }
    }
    if (event.keyCode === 37) {
      const currentFeild = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement

      const prevFeild = document.querySelector(
        `input[name=code${fieldIntIndex - 1}]`
      )

      if (prevFeild !== null) {
        ;(prevFeild as HTMLElement).focus()
      }
    } else if (event.keyCode === 39) {
      const currentFeild = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement

      const nextFeild = document.querySelector(
        `input[name=code${fieldIntIndex + 1}]`
      )

      if (nextFeild !== null) {
        ;(nextFeild as HTMLElement).focus()
      }
    }
  }

  const handleChangeWithNextField = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    setErrorResp('')
    const { maxLength, value, name } = event.target

    const fieldIndex = name.replace('code', '')

    const fieldIntIndex = Number(fieldIndex)

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=code${fieldIntIndex + 1}]`
        )

        if (nextfield !== null) {
          ;(nextfield as HTMLElement).focus()
        }
      }
    }

    handleChange(event)
  }

  useEffect(() => {
    const target = document.querySelector('input.field-code')

    target?.addEventListener('paste', handlePaste)

    return () => {
      target?.removeEventListener('paste', handlePaste)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      key={key}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <OutlinedInput
          {...field}
          error={!!error}
          autoFocus={enableFocus}
          placeholder="-"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeWithNextField(event, field.onChange)
          }
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          inputProps={{
            className: 'field-code',
            maxLength: 1,
            sx: {
              p: 0,
              textAlign: 'center',
              width: { xs: 36, sm: 56 },
              height: { xs: 36, sm: 56 },
            },
          }}
        />
      )}
    />
  )
}
