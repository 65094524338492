// AddEndletFilterForm.jsx
import React from 'react'
import * as Yup from 'yup'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider } from 'src/components/hook-form'
import {
  Box,
  Button,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Stack,
  DialogContent,
  Divider,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Scrollbar from 'src/components/Scrollbar'
import { useLocation } from 'react-router'

const formSchema = {
  label: Yup.string().nullable().required('Filter is required'),
  operator: Yup.string().nullable().required('Operator is required'),
  value: Yup.string().required('Value is required'),
}

const filterSchema = Yup.object().shape({
  test: Yup.array().of(Yup.object().shape(formSchema)),
})

const operators = [
  { key: 'starts_with', value: 'Starts with' },
  { key: 'contains', value: 'Contains' },
  { key: 'equals', value: 'Equals' },
]
const customer_status_options = ['Active', 'Archived']
const user_status_options = ['Active', 'Archived', 'Invited', 'Inactive']

interface Props {
  onSubmit: any
  handleClose: any
  reset: any
}
const AddCustomerFilterForm = ({ onSubmit, handleClose, reset }: Props) => {
  const { pathname } = useLocation()
  const filters = pathname.includes('customer')
    ? [
        { key: 'customer_email', value: 'Customer Email' },
        { key: 'customer_name', value: 'Customer Name' },
        { key: 'customer_status', value: 'Customer Status' },
        { key: 'subdomain', value: 'Subdomain' },
        { key: 'user_name', value: 'User Name' },
        { key: 'user_email', value: 'User Email' },
        { key: 'user_status', value: 'User Status' },
        { key: 'user_role', value: 'User Role' },
      ]
    : [
        { key: 'reseller_email', value: 'Reseller Email' },
        { key: 'reseller_name', value: 'Reseller Name' },
        { key: 'reseller_status', value: 'Reseller Status' },
        { key: 'subdomain', value: 'Subdomain' },
        { key: 'user_name', value: 'User Name' },
        { key: 'user_email', value: 'User Email' },
        { key: 'user_status', value: 'User Status' },
        { key: 'user_role', value: 'User Role' },
        { key: 'customer_name', value: 'Customer Name' },
        { key: 'customer_email', value: 'Customer Email' },
        { key: 'customer_status', value: 'Customer Status' },
      ]

  const user_role_options = pathname.includes('customer')
    ? [
        { key: 'Customer_Admin', value: 'Admin' },
        { key: 'Customer_Engineer', value: 'Enginner' },
        { key: 'Customer_Support', value: 'Support' },
        { key: 'Customer_Finance', value: 'Finance' },
      ]
    : [
        { key: 'Reseller_Admin', value: 'Admin' },
        { key: 'Reseller_Engineer', value: 'Engineer' },
        { key: 'Reseller_Support', value: 'Support' },
        { key: 'Reseller_Finance', value: 'Finance' },
      ]

  const methods = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues: {
      test: [{ label: '', operator: '', value: '' }],
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
  } = methods

  const { test } = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'test',
  })

  const handleChange = (index: any) => {
    test[index].operator = ''
    test[index].value = ''
  }

  const handleOpChange = (index: any) => {
    test[index].value = ''
  }

  const handleDelete = (index: any) => {
    remove(index)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ pr: 2 }}>
        <DialogContent sx={{ my: 0 }}>
          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Stack spacing={2}>
                {' '}
                <Stack key={item.id} spacing={3}>
                  <Box>
                    <Typography>Filter By</Typography>
                    <Box sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex', mt: 0 }}>
                        {filters
                          .filter(
                            (filt) =>
                              (pathname.includes('customer')
                                ? filt.key.includes('customer')
                                : filt.key.includes('reseller')) ||
                              filt.key.includes('subdomain')
                          )
                          .map((filter, idx: any) => {
                            return (
                              <Controller
                                defaultValue={`${item.label}`}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Button
                                      style={{ minWidth: '9em' }}
                                      onClick={() => {
                                        onChange(filter?.key)
                                        handleChange(index)
                                      }}
                                      sx={{ mr: 2, py: 1, px: 0 }}
                                      variant={
                                        test[index]?.label === filter.key
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                    >
                                      {filter.value}
                                    </Button>
                                  </>
                                )}
                                control={control}
                                {...register(`test.${index}.label`)}
                              />
                            )
                          })}
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex', mt: 0 }}>
                        {filters
                          .filter((filt) => filt.key.includes('user'))
                          .map((filter, idx: any) => {
                            return (
                              <Controller
                                defaultValue={`${item.label}`}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Button
                                      style={{ minWidth: '9em' }}
                                      onClick={() => {
                                        onChange(filter?.key)
                                        handleChange(index)
                                      }}
                                      sx={{ mr: 2, py: 1, px: 0 }}
                                      variant={
                                        test[index]?.label === filter.key
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                    >
                                      {filter.value}
                                    </Button>
                                  </>
                                )}
                                control={control}
                                {...register(`test.${index}.label`)}
                              />
                            )
                          })}
                      </Box>
                    </Box>

                    {pathname.includes('reseller') && (
                      <Box sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex', mt: 0 }}>
                          {filters
                            .filter((filt) => filt.key.includes('customer'))
                            .map((filter, idx: any) => {
                              return (
                                <Controller
                                  defaultValue={`${item.label}`}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      <Button
                                        style={{ minWidth: '9em' }}
                                        onClick={() => {
                                          onChange(filter?.key)
                                          handleChange(index)
                                        }}
                                        sx={{ mr: 2, py: 1, px: 0 }}
                                        variant={
                                          test[index]?.label === filter.key
                                            ? 'contained'
                                            : 'outlined'
                                        }
                                      >
                                        {filter.value}
                                      </Button>
                                    </>
                                  )}
                                  control={control}
                                  {...register(`test.${index}.label`)}
                                />
                              )
                            })}
                        </Box>
                      </Box>
                    )}
                    {errors?.test && (
                      <Grid sx={{ mt: 1 }} item lg={12}>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#FF4842', fontWeight: 'normal' }}
                        >
                          <small>
                            {errors?.test !== undefined
                              ? errors?.test[index]?.label?.message
                              : ''}
                          </small>
                        </Typography>
                      </Grid>
                    )}
                  </Box>

                  <Box sx={{ mt: 0 }}>
                    <Typography>Filter By</Typography>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                      {operators
                        .filter((op) => {
                          if (
                            test[index].label === 'customer_status' ||
                            test[index].label === 'reseller_status' ||
                            test[index].label === 'user_status' ||
                            test[index].label === 'user_role'
                          ) {
                            return op.key === 'equals'
                          } else {
                            return op
                          }
                        })
                        .map((operator, idx: any) => {
                          return (
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Button
                                    style={{ minWidth: '9em' }}
                                    onClick={() => {
                                      onChange(operator?.key)
                                      handleOpChange(index)
                                    }}
                                    sx={{ mr: 2, py: 1, px: 0 }}
                                    variant={
                                      test[index]?.operator === operator.key
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                  >
                                    {operator.value}
                                  </Button>
                                </>
                              )}
                              control={control}
                              {...register(`test.${index}.operator`)}
                            />
                          )
                        })}
                    </Box>
                    {errors?.test ? (
                      <Grid sx={{ mt: 1 }} item lg={12}>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#FF4842', fontWeight: 'normal' }}
                        >
                          <small>
                            {' '}
                            {errors?.test !== undefined
                              ? errors?.test[index]?.operator?.message
                              : ''}
                          </small>
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Box>

                  <Box sx={{ mt: 0 }}>
                    <Typography>Search By</Typography>
                    <Box sx={{ mt: 2 }}>
                      {(test[index].label === 'customer_status' ||
                        test[index].label === 'reseller_status' ||
                        test[index].label === 'user_role' ||
                        test[index].label === 'user_status') &&
                      test[index].operator === 'equals' ? (
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={
                                test[index].label === 'customer_status' ||
                                test[index].label === 'reseller_status'
                                  ? customer_status_options
                                  : test[index].label === 'user_status'
                                  ? user_status_options
                                  : user_role_options
                              }
                              getOptionLabel={(option: any) =>
                                test[index].label === 'user_role'
                                  ? option['value']
                                  : option
                              }
                              defaultValue={
                                test[index].label === 'customer_status' ||
                                test[index].label === 'reseller_status'
                                  ? customer_status_options.find(
                                      (status: any) => status === item.value
                                    )
                                  : test[index].label === 'user_status'
                                  ? user_status_options.find(
                                      (status: any) => status === item.value
                                    )
                                  : user_role_options.find(
                                      (role: any) => role.key === item.value
                                    )
                              }
                              onChange={(event, values: any) =>
                                test[index].label === 'user_role'
                                  ? onChange(values['key'])
                                  : onChange(values)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Value"
                                  defaultValue={`${item.value}`}
                                />
                              )}
                            />
                          )}
                          control={control}
                          {...register(`test.${index}.value`)}
                        />
                      ) : (
                        <Controller
                          defaultValue={`${item.value}`}
                          render={({ field }) => {
                            return (
                              <TextField
                                label="Value"
                                name={field.name}
                                value={test[index].value || ''}
                                onChange={field.onChange}
                                fullWidth
                                autoComplete="off"
                              />
                            )
                          }}
                          {...register(`test.${index}.value`)}
                        ></Controller>
                      )}
                    </Box>
                    {errors?.test ? (
                      <Grid sx={{ mt: 1 }} item lg={12}>
                        <Typography
                          variant="subtitle2"
                          style={{ color: '#FF4842', fontWeight: 'normal' }}
                        >
                          <small>
                            {' '}
                            {errors?.test !== undefined
                              ? errors?.test[index]?.value?.message
                              : ''}
                          </small>
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Box>

                  {fields.length !== 1 && (
                    <div>
                      <Button
                        color="error"
                        variant="text"
                        onClick={() => {
                          handleDelete(index)
                        }}
                        sx={{
                          color: 'red',
                          float: 'right',
                          width: 'fit-content',
                          px: 2,
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </Stack>
              </Stack>
              {index !== fields.length - 1 && (
                <Divider sx={{ borderColor: 'darkgray', margin: '10px' }} />
              )}
            </React.Fragment>
          ))}

          <Stack
            direction="row"
            alignItems="center"
            sx={{ mt: 2, float: 'right' }}
            spacing={2}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ float: 'right' }}
              onClick={() => append({ label: '', operator: '', value: '' })}
            >
              + Add more
            </Button>
          </Stack>
        </DialogContent>
      </Box>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="text"
          sx={{ color: 'text.secondary' }}
        >
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained">
          Add
        </LoadingButton>
      </DialogActions>
      {/* Rest of the form code */}
    </FormProvider>
  )
}

export default AddCustomerFilterForm
