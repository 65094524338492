import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { Typography } from '@mui/material'

function InterfaceErrorEach({ graphData, key }: any) {
  const device = graphData[0]?.metric?.device
  console.log(key)
  const processErrors = (data: any) => {
    const values = data.values || []
    const timestamps: any = []
    const yAxisValues: any = []
    values?.forEach((subValues: any) => {
      if (subValues.length === 2) {
        timestamps.push(
          new Date(subValues[0] * 1000)
            .toISOString()
            .replace(/T/, ' ')
            .replace(/\.\d+Z$/, '')
        )
        yAxisValues.push(subValues[1])
      }
    })
    return {
      timestamps,
      yAxisValues,
      device,
    }
  }

  const updatedRecErr =
    graphData &&
    processErrors(
      graphData?.find(
        (item: any) => item.metric.__name__ === 'node_network_receive_errs_total'
      )
    )
  const updatedTransErr =
    graphData &&
    processErrors(
      graphData?.find(
        (item: any) => item.metric.__name__ === 'node_network_transmit_errs_total'
      )
    )
  const updatedRecDrop =
    graphData &&
    processErrors(
      graphData?.find(
        (item: any) => item.metric.__name__ === 'node_network_receive_drop_total'
      )
    )
  const updatedTransDrop =
    graphData &&
    processErrors(
      graphData?.find(
        (item: any) => item.metric.__name__ === 'node_network_transmit_drop_total'
      )
    )
  const updatedRecColl =
    graphData &&
    processErrors(
      graphData?.find(
        (item: any) => item.metric.__name__ === 'node_network_receive_colls_total'
      ) || {}
    )

  const updatedTransColl =
    graphData &&
    processErrors(
      graphData?.find(
        (item: any) => item.metric.__name__ === 'node_network_transmit_colls_total'
      ) || {}
    )

  // ECharts options
  const options = {
    xAxis: {
      type: 'category',
      data: updatedRecErr?.timestamps,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Errors(Transmit)',
        type: 'line',
        data: updatedTransErr.yAxisValues,
        itemStyle: {
          color: 'rgba(255, 87, 51, 0.8)', // Specify the dot color
        },
        lineStyle: {
          color: 'rgba(255, 87, 51, 0.8)', // Specify the line color
        },
        // areaStyle: {
        //   color: 'rgb(255, 0, 0)', // Specify the fill color for the area
        // },
      },
      {
        name: 'Errors(Receive)',
        type: 'line',
        data: updatedRecErr.yAxisValues,
        itemStyle: {
          color: 'rgba(51, 255, 87, 0.8)', // Specify the dot color
        },
        lineStyle: {
          color: 'rgba(51, 255, 87, 0.8)', // Specify the line color
        },
        // areaStyle: {
        //   color: 'rgb(255, 0, 0)', // Specify the fill color for the area
        // },
      },
      {
        name: 'Drops(Transmit)',
        type: 'line',
        data: updatedTransDrop.yAxisValues,
        itemStyle: {
          color: 'rgba(87, 51, 255, 0.8)', // Specify the dot color
        },
        lineStyle: {
          color: 'rgba(87, 51, 255, 0.8)', // Specify the line color
        },
        // areaStyle: {
        //   color: 'rgba(0, 0, 255)', // Specify the fill color for the area
        // },
        // data: drops,
      },
      {
        name: 'Drops(Receive)',
        type: 'line',
        data: updatedRecDrop.yAxisValues,
        itemStyle: {
          color: 'rgba(51, 255, 255, 0.8)', // Specify the dot color
        },
        lineStyle: {
          color: 'rgba(51, 255, 255, 0.8)', // Specify the line color
        },
        // areaStyle: {
        //   color: 'rgba(0, 0, 255)', // Specify the fill color for the area
        // },
        // data: drops,
      },

      {
        name: 'Collisions(transmit)',
        data: updatedTransColl.yAxisValues,
        type: 'line',
        itemStyle: {
          color: 'rgba(255, 255, 51, 0.8)', // Specify the dot color
        },
        lineStyle: {
          color: 'rgba(255, 255, 51, 0.8)', // Specify the line color
        },
        // areaStyle: {
        //   color: 'rgba(255, 165, 0)', // Specify the fill color for the area
        // },
        // data: collisions,
      },
      {
        name: 'Collisions(Receive)',
        data: updatedRecColl.yAxisValues,
        type: 'line',
        itemStyle: {
          color: 'rgba(255, 165, 0)', // Specify the dot color
        },
        lineStyle: {
          color: 'rgba(255, 165, 0)', // Specify the line color
        },
        // areaStyle: {
        //   color: 'rgba(255, 165, 0)', // Specify the fill color for the area
        // },
        // data: collisions,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false, // Set to false to enable zooming only on the x-axis
        },
      },
    },
  }
  return (
    <div>
      {' '}
      <Typography variant="subtitle1" sx={{ pt: 5 }}>
        Interface Errors {device}
      </Typography>
      <ReactEcharts option={options} />
    </div>
  )
}

export default InterfaceErrorEach
