import React, { useEffect, useState } from 'react'
import graphJSON from '../bandwidth.json'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import { Box } from '@mui/material'
// import { DateRange } from '@mui/icons-material'
interface Props {
  data: any
}
function InterfaceStatusChange({
  data: { dateRange, setDateRange, selectedTimeInterval },
}: Props) {
  const originalData = graphJSON.interfaceStatus
  const [interfaceData, setInterfaceData] = useState(graphJSON.interfaceStatus)

  const xData = interfaceData.map((item: any) => item.datetime)
  const xAxisData = interfaceData.map((item) =>
    moment(item.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
  )
  const statusData = interfaceData.map((item) => {
    switch (item.status) {
      case 'Up':
        return 1
      case 'Down':
        return 2
      case 'Testing':
        return 3
      case 'Error':
        return 4
      case 'Unknown':
        return 5
      default:
        return 0
    }
  })
  const options = {
    xAxis: {
      type: 'category', // Use time type for x-axis
      data: xData.map((datetime: any) =>
        moment(datetime).format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value: any) {
          switch (value) {
            case 1:
              return 'Up'
            case 2:
              return 'Down'
            case 3:
              return 'Testing'
            case 4:
              return 'Error'
            case 5:
              return 'Unknown'
            default:
              return ''
          }
        },
      },
    },
    series: [
      {
        name: 'Interface Status',
        type: 'line',
        smooth: true,
        data: statusData,
        lineStyle: {
          color: 'rgba(0, 0, 255)', // Specify the line color
        },
        itemStyle: {
          color: 'rgba(0, 0, 255)', // Specify the dot color
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any) {
        const dataIndex = params[0].dataIndex
        const datetime = xAxisData[dataIndex].toLocaleString() // Format the datetime
        const statusLabel = options.yAxis.axisLabel.formatter(statusData[dataIndex])
        return `${datetime}: ${statusLabel}`
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false, // Set to false to enable zooming only on the x-axis
        },
      },
    },
  }

  ///Filters

  useEffect(() => {
    let filteredData = originalData
    if (dateRange.toDate && dateRange.fromDate) {
      const fromNewDate = moment(dateRange.fromDate).format('YYYY-MM-DD HH:mm:ss')
      const toNewDate = moment(dateRange.toDate).format('YYYY-MM-DD HH:mm:ss')
      const startDate = new Date(fromNewDate).getTime()
      const endDate = new Date(toNewDate).getTime()
      filteredData = filteredData.filter((item: any) => {
        const datetime = new Date(item.datetime).getTime()
        return datetime >= startDate && datetime <= endDate
      })
    }

    setInterfaceData(filteredData)
  }, [dateRange])

  useEffect(() => {
    if (selectedTimeInterval) {
      if (selectedTimeInterval === 'Last 7 days') {
        const today = new Date()
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(today.getDate() - 7)

        setDateRange({
          fromDate: sevenDaysAgo,
          toDate: today,
        })
      }
    }
  }, [selectedTimeInterval])
  return (
    <div>
      {interfaceData.length === 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: 250, width: '100%', bgcolor: '#f5f5f5', p: 4, pt: 1, pb: 2 }}
        >
          <h5>No Data</h5>
        </Box>
      ) : (
        <ReactEcharts option={options} />
      )}
    </div>
  )
}

export default InterfaceStatusChange
