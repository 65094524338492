import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import { Box } from '@mui/material'
import BandwidthBytesEach from './BandwidthBytesEach'

interface Props {
  graphData: {
    transmitBytes: any
    receivedBytes: any
  }
  selectedDevices: Array<String>
}

function BandwidthBytes({
  graphData: { transmitBytes, receivedBytes },
  selectedDevices,
}: Props) {
  const processBytes = (bytesData: any) => {
    const data = bytesData?.map((obj: any) => {
      const values = obj.values || []
      const timestamps: any = []
      const yAxisValues: any = []
      const device = obj.metric.device
      values?.forEach((subValues: any) => {
        if (subValues.length === 2) {
          timestamps.push(
            new Date(subValues[0] * 1000)
              .toISOString()
              .replace(/T/, ' ')
              .replace(/\.\d+Z$/, '')
          )
          yAxisValues.push(subValues[1])
        }
      })

      return {
        timestamps,
        yAxisValues,
        device,
      }
    })
    return data.filter((each: any) => selectedDevices.includes(each.device))
  }

  const updatedTransBytes = transmitBytes && processBytes(transmitBytes)
  const updatedRecBytes = receivedBytes && processBytes(receivedBytes)

  return (
    <div>
      {updatedRecBytes?.map((rBytes: any, index: number) => (
        <BandwidthBytesEach rBytes={rBytes} tBytes={updatedTransBytes[index]} />
      ))}
    </div>
  )
}

export default BandwidthBytes
