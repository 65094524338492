import React, { useState } from 'react'
import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
const InterfaceFields = [
  'Interface Name',
  'Interface Status ',
  'Interface Speed',
  'Interface Duplex',
  'Interface MAC Address',
]

function ObservInterfaceTable() {
  const [loading, setLoading] = useState(false)
  const interfaces = [
    {
      name: 'eth0',
      status: 'Up',
      speed: '1 Gbps',
      duplex: 'Full',
      macAddress: '00:11:22:33:44:55',
    },
    {
      name: 'eth1',
      status: 'Up',
      speed: '10 Gbps',
      duplex: 'Full',
      macAddress: '11:22:33:44:55:66',
    },
  ]
  return (
    <div>
      <TableContainer sx={{ minWidth: 800, position: 'relative', mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {InterfaceFields.map((fields) => (
                <>
                  <TableCell
                    sx={{
                      backgroundColor: 'tableHead',
                      color: 'white',
                      '&:first-of-type': {
                        boxShadow: 'unset',
                      },

                      '&:last-of-type': {
                        boxShadow: 'unset',
                      },
                    }}
                    align="left"
                  >
                    <Stack direction="row" spacing={1}>
                      <span style={{ cursor: 'pointer' }}>
                        {fields.toUpperCase()}
                      </span>
                    </Stack>
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Box>
                      <Skeleton animation="wave" height={60} />
                      <Skeleton animation="wave" height={60} />
                      <Skeleton animation="wave" height={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                interfaces.map((eachIfs: any) => (
                  <>
                    <TableRow>
                      <TableCell>{eachIfs.name}</TableCell>
                      <TableCell>{eachIfs.status}</TableCell>
                      <TableCell>{eachIfs.speed}</TableCell>
                      <TableCell>{eachIfs.duplex}</TableCell>
                      <TableCell>{eachIfs.macAddress}</TableCell>
                    </TableRow>
                  </>
                ))
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ObservInterfaceTable
