import { Suspense, lazy, ElementType } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// layouts
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// components
import LoadingScreen from '../components/LoadingScreen'
import TestComponent from 'src/components/TestComponent'
import EndletDetails from 'src/sections/@dashboard/Services/EndletDetails'
import ProvisioningDetails from 'src/pages/super-user/ProvisioningDetails'
import CompanySettings from 'src/pages/dashboard/CompanySettings'
import SecuritySettings from 'src/pages/dashboard/SecuritySettings'
import EndletMainPage from 'src/pages/dashboard/EndletMainPage'
import ResellerManagement from 'src/pages/super-user/ResellerManagement'
import AddQRtoAccount from 'src/pages/auth/AddQRtoAccount'
import AuthenticatorCode from 'src/pages/auth/AuthenticatorCode'
import ServiceDetails from 'src/sections/@dashboard/Services/ServiceDetails'
import BackupCodes from 'src/sections/auth/verify-code/BackupCodes'
import IntermidiateEdletDetails from 'src/sections/@dashboard/Services/IntermidiateEdletDetails'
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: 'auth',

      children: [
        { element: <Navigate to="/company/login" replace />, index: true },

        {
          path: 'company/login',
          element: <Subdomain />,
        },
        {
          path: 'login',
          element: <Login />,
        },

        {
          path: 'logging-in',
          element: <LoginMessage />,
        },

        {
          path: 'register',
          element: <Register />,
        },
        {
          path: 'deactivated',
          element: <DeactivatedAccNotification />,
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify-user', element: <VerifyUser /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'accept-invite', element: <Register /> },
        { path: 'terms', element: <Terms /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'two-factor-auth', element: <TwoFactorAuth /> },
        { path: 'multi-factor-auth', element: <MultiFactorAuth /> },
        { path: 'verify-otp', element: <AddQRtoAccount /> },
        { path: 'verify-authenticator', element: <AuthenticatorCode /> },
        { path: 'recover/:num', element: <AddQRtoAccount /> },
        { path: 'backup-codes', element: <BackupCodes /> },
        { path: 'enroll-duo', element: <EnrollDuoUser /> },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <DefaultDashboard /> },

        { path: 'billing', element: <Billing /> },
        { path: 'message-center/:id', element: <MessageCenter /> },
        { path: 'message-center/:category', element: <GeneralMessage /> },
        { path: 'message-center', element: <GeneralMessage /> },
        // {
        //   path: '/services',
        //   element: <Services />,
        // },
        {
          path: '/endlets',
          element: <EndletMainPage />,
        },
        // {
        //   path: '/endlets/services',
        //   element: <EndletMainPage />,
        // },
        // {
        //   path: '/endlets/endletMap',
        //   element: <EndletMainPage />,
        // },
        // {
        //   path: '/endlets/:id/configuration',
        //   element: <EndletDetails />,
        // },
        // {
        //   path: '/endlets/:id/observability',
        //   element: <EndletDetails />,
        // },
        // {
        //   path: '/endlets/:id/detail',
        //   element: <EndletDetails />,
        // },
        // {
        //   path: '/endlets/:id/services',
        //   element: <EndletDetails />,
        // },
        { path: 'test', element: <TestComponent /> },
      ],
    },
    {
      path: '/endlets',
      element: <DashboardLayout />,
      children: [
        // {
        //   path: '/endlets',
        //   element: <EndletMainPage />,
        // },
        {
          path: 'services',
          element: <EndletMainPage />,
        },
        {
          path: 'endletMap',
          element: <EndletMainPage />,
        },
        {
          path: ':id/configuration',
          element: <EndletDetails />,
        },
        {
          path: ':id/observability',
          element: <EndletDetails />,
        },
        {
          path: ':id/intermediate',
          element: <IntermidiateEdletDetails />,
        },
        {
          path: ':id/services',
          element: <EndletDetails />,
        },
        {
          path: 'services/:id',
          element: <ServiceDetails />,
        },
      ],
    },
    {
      path: '/user',
      element: <DashboardLayout />,
      children: [
        {
          path: 'admin',
          children: [
            {
              path: 'security',
              element: <SecuritySettings />,
              children: [
                {
                  path: 'sso',
                  element: <SecuritySettings />,
                },
                {
                  path: 'security-credentials',
                  element: <SecuritySettings />,
                },
                {
                  path: 'duo-credentials',
                  element: <SecuritySettings />,
                },
              ],
            },

            // {
            //   index: true,
            //   element: <Navigate to="/user/admin/ssh" replace />,
            // },
            {
              path: 'company-settings',
              element: <CompanySettings />,
            },
            {
              path: 'users',
              element: <Settings />,
            },
          ],
        },
        { path: 'profile', element: <UserAccount /> },
        { path: 'change-password', element: <UserAccount /> },
        { path: 'notifications', element: <UserAccount /> },
        { path: 'security-credentials', element: <UserAccount /> },
      ],
    },
    {
      path: '/super-user',
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="/super-user/customers" replace />,
        },
        {
          path: 'customers',
          element: <ManageCustomers />,
        },
        {
          path: 'customers/:id',
          element: <ManageCustomers />,
        },
        { path: 'company-settings', element: <CompanySettings /> },
        { path: 'messages/:id', element: <MessageCenter /> },
        { path: 'reseller', element: <ResellerManagement /> },
        {
          path: 'reseller/:reseller_id',
          element: <ResellerManagement />,
        },
        { path: 'messages', element: <ManageMessage /> },
        { path: 'manage-employees', element: <ManageEmployees /> },
        { path: 'provisioning', element: <Provisioning /> },
        {
          path: 'provisioning/:id/configuration',
          element: <ProvisioningDetails />,
        },
        {
          path: 'provisioning/:id/detail',
          element: <ProvisioningDetails />,
        },
        {
          path: 'provisioning/:id/services',
          element: <ProvisioningDetails />,
        },
      ],
    },

    {
      path: '/support',
      element: <DashboardLayout />,

      children: [
        // { path: 'ticket-details', element: <TicketDetails /> },

        {
          path: 'support-center',
          element: <SupportCenter />,
          children: [
            {
              index: true,
              element: <Navigate to="cases" replace />,
            },
            { path: 'cases', element: <CasesTable />, index: true },
            { path: 'cases/:id', element: <TicketDetails /> },
          ],
        },
        { path: 'knowledge-base', element: <KnowledgeBase /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const LoginMessage = Loadable(
  lazy(() => import('../sections/auth/verify-code/LoggingInMessage'))
)

const Subdomain = Loadable(lazy(() => import('../pages/auth/Subdomain')))
const Register = Loadable(lazy(() => import('../pages/auth/Register')))
const DeactivatedAccNotification = Loadable(
  lazy(() => import('../sections/auth/DeactivatedAccNotification'))
)
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')))
const VerifyUser = Loadable(lazy(() => import('../pages/auth/VerifyUser')))
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')))
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')))
const Terms = Loadable(lazy(() => import('../pages/auth/Terms')))
const PrivacyPolicy = Loadable(lazy(() => import('../pages/auth/PrivacyPolicy')))
const TwoFactorAuth = Loadable(lazy(() => import('../pages/auth/TwoFactorAuth')))
const MultiFactorAuth = Loadable(lazy(() => import('../pages/auth/MultiFactorAuth')))
const EnrollDuoUser = Loadable(
  lazy(() => import('../pages/auth/DuoAuth/EnrollDuoUser'))
)

// Dashboard
const DefaultDashboard = Loadable(lazy(() => import('../pages/DefaultDashboard')))
const NotFound = Loadable(lazy(() => import('../pages/Page404')))

//

const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')))

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')))
const ManageMessage = Loadable(
  lazy(() => import('../pages/super-user/SuperUserMessage'))
)
const GeneralMessage = Loadable(
  lazy(() => import('../pages/dashboard/MessageGeneral'))
)

const Billing = Loadable(
  lazy(() => import('../pages/dashboard/BillingPage/Billing'))
)
const MessageCenter = Loadable(
  lazy(() => import('../pages/dashboard/MessageCenter'))
)

// support
const SupportCenter = Loadable(
  lazy(() => import('../pages/dashboard/SupportCenter'))
)
const TicketDetails = Loadable(
  lazy(() => import('../pages/dashboard/TicketDetails'))
)

const CasesTable = Loadable(
  lazy(() => import('../sections/@dashboard/support-center/TicketsDisplay'))
)

const ManageCustomers = Loadable(
  lazy(() => import('../pages/super-user/ManageCustomers'))
)
const ManageEmployees = Loadable(
  lazy(
    () => import('../sections/@dashboard/super-user/manage-employees/EmployeeList')
  )
)
const Provisioning = Loadable(lazy(() => import('../pages/super-user/Provisioning')))

const KnowledgeBase = Loadable(lazy(() => import('../pages/support/KnowledgeBase')))
