import 'react-dates/initialize'
import { Typography } from '@mui/material'
import React, { useState } from 'react'
import ObservInterfaceTable from './Services/ObservInterfaceTable'
import InterfaceGraphs from './Services/InterfaceGraphs'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DetailEpTab from './Services/DetailEpTab'
import { useTheme } from '@mui/material/styles'
interface Props {
  endlet: any
}

function Observability({ endlet }: Props) {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<Array<boolean>>([true, false, false])
  const accordions = [
    { panel: 'panel1', label: 'Interfaces', content: <ObservInterfaceTable /> },
    { panel: 'panel2', label: 'IP', content: <DetailEpTab endlet={endlet} /> },
    { panel: 'panel3', label: 'Operational Insights', content: <InterfaceGraphs /> },
  ]
  const handleChange = (index: any) => {
    const newArr = [...expanded]
    newArr[index] = !expanded[index]
    setExpanded(newArr)
  }

  return (
    <div>
      {accordions.map((accordion, index) => (
        <>
          <Accordion
            expanded={expanded[index]}
            onChange={() => handleChange(index)}
            sx={{
              borderTop: expanded[index]
                ? `3px solid ${theme.palette.primary.main}`
                : '',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="h2">
                {accordion.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{accordion.content}</AccordionDetails>
          </Accordion>
        </>
      ))}
    </div>
  )
}

export default Observability
