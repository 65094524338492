import React, { useContext, useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { OpenModalProps } from 'src/@types/modalProps'
import Iconify from './Iconify'
import Page from './Page'
import Scrollbar from './Scrollbar'
import { get } from 'src/utils/httpMethods'
import { TenantsContext } from 'src/contexts/TenantsContext'
import useAuth from 'src/hooks/useAuth'

function SelectTenantModal({
  openProps,
  list,
  selectTenant,
  setList,
}: {
  openProps: OpenModalProps
  list: any[]
  setList: React.Dispatch<any>
  selectTenant: {
    selectedTenant: any
    setSelectedTenant: React.Dispatch<React.SetStateAction<any>>
  }
}) {
  const { open, setOpen } = openProps
  const { user } = useAuth()
  const { selectedTenant: selected, setSelectedTenant: setSelected } = selectTenant

  const [filterName, setFilterName] = useState('')
  const [modalTenant, setModalTenant] = useState<any>(selected ? selected : {})

  const handleClose = () => {
    setOpen(false)
  }

  const onFilterName = (filterName: string) => {
    setFilterName(filterName)
  }

  const fetchTenants = async () => {
    const SUB_URL =
      user?.role === 'Reseller_Admin' ? 'resellers/customers' : 'customers'
    try {
      const res = await get(
        `${process.env.REACT_APP_HOST_API_URL}/${SUB_URL}?term=${filterName}`
      )
      setList(res)
    } catch (e) {
      console.error(e)
    }
  }

  const { setSelectedTenant } = useContext(TenantsContext)!

  useEffect(() => {
    fetchTenants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName])

  return (
    <Page title="Select Tenant">
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>Select Tenant</DialogTitle>
        <TextField
          autoFocus
          sx={{ px: 3, py: 2 }}
          fullWidth
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          placeholder="Search Tenant..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />

        <DialogContent dividers={true} sx={{ width: 550 }}>
          <Scrollbar>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <List component="nav" aria-label="main mailbox folders">
                {/* <ListItemButton key={'blank'} defaultChecked>
                  <ListItemText primary={'Select a tenant'} />
                </ListItemButton> */}

                {list
                  .filter((tenant) => tenant.isActive && tenant.status === 'Active')
                  .map((tenant, i) => (
                    <ListItemButton
                      key={tenant.id}
                      selected={modalTenant?.id === tenant.id}
                      onClick={() => {
                        setModalTenant(tenant)
                      }}
                    >
                      <ListItemText primary={tenant.name} />
                    </ListItemButton>
                  ))}
              </List>
              <Divider />
            </Box>
          </Scrollbar>
        </DialogContent>
        <DialogActions sx={{ float: 'bottom' }}>
          <Button
            onClick={handleClose}
            variant="text"
            sx={{ color: 'text.secondary' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose()
              setSelected(modalTenant)
              setSelectedTenant(modalTenant.id)
            }}
            variant="contained"
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default SelectTenantModal
