import React, { useEffect, useState } from 'react'
import { countries } from './FiscalYears'
import useAuth from 'src/hooks/useAuth'
import { calculateFiscalYearDates } from './FiscalYearIntervals'
function DateRangeSelector({ selectedTimeInterval, setDateRange }: any) {
  const { organization } = useAuth()
  const country = countries[organization?.address?.country]
  useEffect(() => {
    const calculateDateRange = () => {
      const currentDate = new Date()
      let fromDate = new Date()
      let toDate = currentDate
      const currentDay = currentDate.getDay()

      function getDateRangeForDaysAgo(daysAgo: any) {
        const targetDate = new Date(currentDate)
        targetDate.setDate(currentDate.getDate() - daysAgo) // Subtracted the specified number of days
        const dayStart = new Date(targetDate)
        dayStart.setHours(0, 0, 0, 0) // Set to midnight (00:00:00)
        const dayEnd = new Date(targetDate)
        dayEnd.setHours(23, 59, 59, 999) // Set to the end of the day (23:59:59.999)
        return { dayStart, dayEnd }
      }
      // Calculated the fromDate based on the selected time interval
      switch (selectedTimeInterval) {
        case '5m':
          fromDate = new Date(currentDate.getTime() - 5 * 60 * 1000)
          break
        case '15m':
          fromDate = new Date(currentDate.getTime() - 15 * 60 * 1000)
          break
        case '30m':
          fromDate = new Date(currentDate.getTime() - 30 * 60 * 1000)
          break
        case '1h':
          fromDate = new Date(currentDate.getTime() - 60 * 60 * 1000)
          break
        case '3h':
          fromDate = new Date(currentDate.getTime() - 3 * 60 * 60 * 1000)
          break
        case '6h':
          fromDate = new Date(currentDate.getTime() - 6 * 60 * 60 * 1000)
          break
        case '12h':
          fromDate = new Date(currentDate.getTime() - 12 * 60 * 60 * 1000)
          break
        case '24h':
          fromDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)
          break
        case '2d':
          fromDate = new Date(currentDate.getTime() - 2 * 24 * 60 * 60 * 1000)
          break
        case '7d':
          fromDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          break
        case '30d':
          fromDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000)
          break
        case '90d':
          fromDate = new Date(currentDate.getTime() - 90 * 24 * 60 * 60 * 1000)
          break
        case '6mn':
          fromDate = new Date(currentDate.getTime() - 6 * 30 * 24 * 60 * 60 * 1000)
          break
        case '1y':
          fromDate = new Date(currentDate.getTime() - 365 * 24 * 60 * 60 * 1000)
          break
        case '2y':
          fromDate = new Date(currentDate.getTime() - 2 * 365 * 24 * 60 * 60 * 1000)
          break
        case '5y':
          fromDate = new Date(currentDate.getTime() - 5 * 365 * 24 * 60 * 60 * 1000)
          break
        case 'yesterday':
          const yesterday = getDateRangeForDaysAgo(1) // 1 day ago
          fromDate = yesterday.dayStart
          toDate = yesterday.dayEnd

          break
        case 'day_before_yes':
          const res = getDateRangeForDaysAgo(2) // 1 day ago
          fromDate = res.dayStart
          toDate = res.dayEnd

          break
        case 'last_week':
          fromDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          toDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          break
        case 'prev_week':
          fromDate = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000)
          toDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          break
        case 'prev_month':
          fromDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000)
          toDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          break
        case 'today':
          // Calculated today's date range (from midnight to the current time)
          fromDate = new Date(currentDate.setHours(0, 0, 0, 0))
          const endOfDay = new Date(currentDate)
          endOfDay.setHours(23, 59, 59, 999)
          toDate = endOfDay
          break

        case 'today_so_far':
          // Calculated today's date range up to the current time
          fromDate = new Date(currentDate.setHours(0, 0, 0, 0))
          break

        case 'this_week':
          // Calculated this week's date range (from the beginning of the current week)

          fromDate = new Date(
            currentDate.getTime() - currentDay * 24 * 60 * 60 * 1000
          )
          break

        case 'this_week_so_far':
          fromDate = new Date(
            currentDate.getTime() - currentDay * 24 * 60 * 60 * 1000
          )
          break

        case 'this_mon':
          // Calculated this month's date range (from the beginning of the current month)
          fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
          break

        case 'this_mon_so_far':
          // Calculated this month's date range up to the current time
          fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
          break

        case 'prev_f_year':
          // Calculated this year's date range (from the beginning of the current year)
          const prevFiscalYear: any = calculateFiscalYearDates(
            'prev_f_year',
            country
          )
          fromDate = prevFiscalYear?.start
          toDate = prevFiscalYear?.end
          break
        case 'prev_f_quar':
          // Calculated this year's date range (from the beginning of the current year)
          const prevFiscalQuar: any = calculateFiscalYearDates(
            'prev_f_quar',
            country
          )
          fromDate = prevFiscalQuar?.start
          toDate = prevFiscalQuar?.end
          break

        case 'this_year':
          // Calculated this year's date range (from the beginning of the current year)
          fromDate = new Date(currentDate.getFullYear(), 0, 1)
          toDate = new Date(currentDate.getFullYear(), 11, 31, 23, 59, 59, 999)
          break

        case 'this_year_so_far':
          // Calculated this year's date range up to the current time
          fromDate = new Date(currentDate.getFullYear(), 0, 1)

          break

        case 'this_fiscal_year':
          // Calculated this year's date range up to the current time
          const fiscalYear: any = calculateFiscalYearDates(
            'this_fiscal_year',
            country
          )
          fromDate = fiscalYear?.start
          toDate = fiscalYear?.end
          break

        case 'this_fiscal_year_so_far':
          // Calculated this year's date range up to the current time
          const fiscalYearSoFar: any = calculateFiscalYearDates(
            'this_fiscal_year_so_far',
            country
          )
          fromDate = fiscalYearSoFar?.start
          toDate = fiscalYearSoFar?.end
          break

        case 'this_fiscal_quar':
          // Calculated this year's date range up to the current time
          const fiscalQuarYear: any = calculateFiscalYearDates(
            'this_fiscal_quar',
            country
          )
          fromDate = fiscalQuarYear?.start
          toDate = fiscalQuarYear?.end
          break

        case 'this_fiscal_quar_so_far':
          //as it is same as  this fiscal year so far
          const fiscalQuarSoFar: any = calculateFiscalYearDates(
            'this_fiscal_year_so_far',
            country
          )
          fromDate = fiscalQuarSoFar?.start
          toDate = fiscalQuarSoFar?.end
          break

        default:
          // Default to 15 minutes if the selected interval is not recognized
          fromDate = new Date(currentDate.getTime() - 15 * 60 * 1000)
          break
      }

      setDateRange({
        fromDate: fromDate,
        toDate: toDate,
      })
    }

    calculateDateRange()
  }, [selectedTimeInterval])

  return null
}

export default DateRangeSelector
