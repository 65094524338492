import { Grid, Typography } from '@mui/material'
import React from 'react'

interface props {
  errors: any
  field: string
  index: number
}
function FormErrors({ errors, field, index }: props) {
  return (
    <div>
      {errors?.test ? (
        <Grid item lg={12}>
          <Typography
            variant="subtitle2"
            style={{ color: '#FF4842', fontWeight: 'normal' }}
          >
            <small>
              {' '}
              {errors?.test !== undefined
                ? errors?.test[index]?.[field]?.message
                : ''}
            </small>
          </Typography>
        </Grid>
      ) : (
        ''
      )}
    </div>
  )
}

export default FormErrors
