import { Box, Typography } from '@mui/material'
import React, { useRef } from 'react'
import ReactEcharts from 'echarts-for-react'

function BandwidthPacketsEach({ rPackets, tPackets }: any) {
  console.log(rPackets, tPackets)
  const chartRef = useRef<any>(null)
  const options = {
    xAxis: {
      type: 'category',
      data: rPackets?.timestamps,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Received Packets',
        type: 'line',
        smooth: true,
        lineStyle: {
          color: 'rgba(255, 255, 0)', // Specify the line color
          width: 3,
        },
        itemStyle: {
          color: 'rgba(255, 255, 0)', // Specify the dot color
        },
        areaStyle: {
          color: 'rgba(255, 255, 0, 0.2)', // Specify the fill color for the area
        },
        data: rPackets?.yAxisValues,
      },

      {
        name: 'Transmitted Packets',
        type: 'line',
        lineStyle: {
          color: 'rgba(152, 251, 152)', // Specify the line color
          width: 3,
        },
        itemStyle: {
          color: 'rgba(152, 251, 152)', // Specify the dot color
        },
        areaStyle: {
          color: 'rgba(152, 251, 152,0.2)', // Specify the fill color for the area
        },
        data: tPackets?.yAxisValues,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false, // Set to false to enable zooming only on the x-axis
        },
      },
    },
  }
  return (
    <div>
      {' '}
      {!rPackets && !tPackets ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: 250, width: '100%', bgcolor: '#f5f5f5', p: 4, pt: 1, pb: 2 }}
        >
          <h5>No Data</h5>
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" sx={{ pt: 5 }}>
            Bandwidth Usage (Packets) {rPackets?.device}
          </Typography>
          <ReactEcharts option={options} ref={chartRef} />
        </>
      )}
    </div>
  )
}

export default BandwidthPacketsEach
