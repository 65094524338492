import React, { useEffect, useState } from 'react'
import InterfaceErrorEach from './InterfaceErrorEach'
interface Props {
  errorProps: {
    transmitErrors: Array<any>
    receivedErrors: any
  }
  dropProps: {
    transmitDrops: any
    receivedDrops: any
  }
  collProps: {
    transmitColls: any
    receivedColls: any
  }
  metricData: any
  selectedDevices: Array<String>
}
function InterfaceError({
  errorProps: { transmitErrors, receivedErrors },
  dropProps: { transmitDrops, receivedDrops },
  collProps: { transmitColls, receivedColls },
  metricData,
  selectedDevices,
}: Props) {
  const [errorsData, seterrorsData] = useState<any>({})

  useEffect(() => {
    if (metricData) {
      const unionArr = [
        ...(transmitErrors || []),
        ...(receivedErrors || []),
        ...(transmitDrops || []),
        ...(receivedDrops || []),
        ...(transmitColls || []),
        ...(receivedColls || []),
      ]

      const groupedByDevice: any = {}

      unionArr.length &&
        unionArr?.forEach((obj) => {
          const device = obj?.metric?.device
          if (groupedByDevice.hasOwnProperty(device)) {
            groupedByDevice[device].push(obj)
          } else {
            groupedByDevice[device] = [obj]
          }
        })
      //to filter data object with selected devices only
      const filteredObject: any = {}
      selectedDevices.forEach((key: any) => {
        if (groupedByDevice.hasOwnProperty(key)) {
          filteredObject[key] = groupedByDevice[key]
        }
      })
      seterrorsData(filteredObject)
    }
  }, [metricData, selectedDevices])

  return (
    <div>
      {Object.keys(errorsData).length
        ? Object.keys(errorsData).map((data) => (
            <InterfaceErrorEach graphData={errorsData[data]} key={data} />
          ))
        : ''}
    </div>
  )
}

export default InterfaceError
