import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from '@mui/material'
import React, { useState, useContext } from 'react'
import { OpenModalProps } from 'src/@types/modalProps'
import { reset } from 'numeral'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import EndletMapFilter from './EndletFilterForm'
import AddServiceFilterForm from '../../AddServiceFilterForm'
import { LoadingButton } from '@mui/lab'
import ServiceFilterForm from './ServiceFilterForm'

interface Props {
  openProps: OpenModalProps
  filterProps: any
}
function AddFuncMapFilter({ openProps, filterProps }: Props) {
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const { open, setOpen } = openProps
  const { filterItems, setFilterItems } = filterProps

  const [value, setValue] = useState('endlets')
  const handleClose = () => {
    setOpen(false)
    reset()
    setValue('endlets')
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  const onEndletSubmit = async (data: any) => {
    const tempTestArr = data.test.map((dt: any) => {
      const newObj = { ...dt }
      newObj['basedOn'] = 'Endlets'
      return newObj
    })
    // const tempFilterItems = filterItems.map((dt: any) => {
    //   const newObj = { ...dt }
    //   newObj['basedOn'] = 'Endlets'
    //   return newObj
    // })
    const unionArr = [...tempTestArr, ...filterItems]
    console.log(unionArr)
    // const unionArr = [...data.test, ...filterItems]
    console.log(unionArr)

    const duplicate = unionArr.filter(
      (tag: any, index: any, array: any) =>
        array.findIndex(
          (t: any) =>
            t.basedOn === tag.basedOn &&
            t.label === tag.label &&
            t.operator === tag.operator &&
            t.value === tag.value
        ) !== index
    )
    if (duplicate.length > 0) {
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Duplicate Filter`,
      })
    } else {
      setFilterItems((prev: any) => [...prev].concat([...tempTestArr]))
      handleClose()
    }
  }

  const onServiceSubmit = async (data: any) => {
    const tempTestArr = data.test.map((dt: any) => {
      const newObj = { ...dt }
      newObj['basedOn'] = 'Services'
      return newObj
    })
    // const tempFilterItems = filterItems.map((dt: any) => {
    //   const newObj = { ...dt }
    //   newObj['basedOn'] = 'Services'
    //   return newObj
    // })
    const unionArr = [...tempTestArr, ...filterItems]
    console.log(unionArr)
    // const unionArr = [...data.test, ...filterItems]

    const duplicate = unionArr.filter((tag: any, index: any, array: any) =>
      tag.sub_type === ''
        ? array.findIndex(
            (t: any) =>
              t.basedOn === tag.basedOn &&
              t.label === tag.label &&
              t.operator === tag.operator &&
              t.value === tag.value
          ) !== index
        : array.findIndex(
            (t: any) =>
              t.basedOn === tag.basedOn &&
              t.label === tag.label &&
              t.operator === tag.operator &&
              t.sub_type === tag.sub_type
          ) !== index
    )
    if (duplicate.length > 0) {
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Duplicate Filter`,
      })
    } else {
      setFilterItems((prev: any) => [...prev].concat([...tempTestArr]))
      handleClose()
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <Box>
          <DialogTitle>Apply Filter</DialogTitle>
          <DialogContent sx={{ my: 0, overflow: 'hidden' }}>
            <Box sx={{ mt: 2, mb: 3 }}>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="endlets"
                  control={<Radio />}
                  label="Endlets"
                />
                <FormControlLabel
                  value="services"
                  control={<Radio />}
                  label="Services"
                />
              </RadioGroup>
            </Box>

            {value === 'endlets' ? (
              <>
                <EndletMapFilter
                  onSubmit={onEndletSubmit}
                  handleClose={handleClose}
                  reset={reset}
                />
              </>
            ) : (
              <>
                <ServiceFilterForm
                  onSubmit={onServiceSubmit}
                  handleClose={handleClose}
                  reset={reset}
                />
              </>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  )
}

export default AddFuncMapFilter
