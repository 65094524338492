import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import { Box } from '@mui/material'
import BandwidthPacketsEach from './BanwidthPacketsEach'

interface Props {
  graphData: {
    transmitPackets: any
    receivedPackets: any
  }
  selectedDevices: Array<String>
}

function BandwidthPackets({
  graphData: { transmitPackets, receivedPackets },
  selectedDevices,
}: Props) {
  const processBytes = (bytesData: any) => {
    const data = bytesData?.map((obj: any) => {
      const values = obj.values || []
      const timestamps: any = []
      const yAxisValues: any = []
      const device = obj.metric.device
      values?.forEach((subValues: any) => {
        if (subValues.length === 2) {
          timestamps.push(
            new Date(subValues[0] * 1000)
              .toISOString()
              .replace(/T/, ' ')
              .replace(/\.\d+Z$/, '')
          )
          yAxisValues.push(subValues[1])
        }
      })

      return {
        timestamps,
        yAxisValues,
        device,
      }
    })
    return data.filter((each: any) => selectedDevices.includes(each.device))
  }

  const updatedTransPackets = transmitPackets && processBytes(transmitPackets)
  const updatedRecPackets = receivedPackets && processBytes(receivedPackets)

  return (
    <div>
      {updatedRecPackets?.map((rPackets: any, index: number) => (
        <BandwidthPacketsEach
          rPackets={rPackets}
          tPackets={updatedTransPackets[index]}
        />
      ))}
    </div>
  )
}

export default BandwidthPackets

// interface Props {
//   data: any
//   apiResponse: any
// }
// function BandwidthPackets({
//   data: { dateRange, setDateRange, selectedTimeInterval },
//   apiResponse,
// }: Props) {
//   const originalData = graphJSON.packetBandwidth
//   const [packetsDataGraph2, setPacketsDataGraph2] = useState(originalData)
//   const [packetData, setPacketData] = useState<any>({
//     xAxis: [],
//     packets: [],
//   })

//   // Prepare x-axis and y-axis data
//   const xAxisData = packetsDataGraph2.map((item: any) =>
//     moment(item.datetime).format('YYYY-MM-DD HH:mm:ss')
//   )
//   const packets = packetsDataGraph2.map((item) => item.packets)

//   const optionsGraph2 = {
//     xAxis: {
//       type: 'category',
//       // data: xAxisData.map((timestamp: any) =>
//       //   moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
//       // ),
//       // data: xAxisData,
//       data: packetData.xAxis,
//     },
//     yAxis: {
//       type: 'value',
//     },
//     series: [
//       {
//         name: 'Packets',
//         type: 'line',
//         itemStyle: {
//           color: 'rgba(0, 123, 255)', // Specify the dot color
//         },
//         lineStyle: {
//           color: 'rgba(0, 123, 255)', // Specify the line color
//         },
//         // areaStyle: {
//         //   color: 'rgba(0, 123, 255)', // Specify the fill color for the area
//         // },
//         // data: packets,
//         data: packetData.packets,
//       },
//     ],
//     tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//         type: 'shadow',
//       },
//     },
//     toolbox: {
//       feature: {
//         dataZoom: {
//           yAxisIndex: false, // Set to false to enable zooming only on the x-axis
//         },
//       },
//     },
//   }

//   useEffect(() => {
//     let filteredData = originalData
//     if (dateRange.toDate && dateRange.fromDate) {
//       const fromNewDate = moment(dateRange.fromDate).format('YYYY-MM-DD HH:mm:ss')
//       const toNewDate = moment(dateRange.toDate).format('YYYY-MM-DD HH:mm:ss')
//       const startDate = new Date(fromNewDate).getTime()
//       const endDate = new Date(toNewDate).getTime()
//       filteredData = filteredData.filter((item: any) => {
//         const datetime = new Date(item.datetime).getTime()
//         return datetime >= startDate && datetime <= endDate
//       })
//     }

//     setPacketsDataGraph2(filteredData)
//   }, [dateRange])

//   useEffect(() => {
//     if (selectedTimeInterval) {
//       if (selectedTimeInterval === 'Last 7 days') {
//         const today = new Date()
//         const sevenDaysAgo = new Date()
//         sevenDaysAgo.setDate(today.getDate() - 7)
//         console.log('today.....->', today)
//         console.log('7 day---->', sevenDaysAgo)
//         setDateRange({
//           fromDate: sevenDaysAgo,
//           toDate: today,
//         })
//       }
//     }
//   }, [selectedTimeInterval])

//   useEffect(() => {
//     if (apiResponse) {
//       console.log(apiResponse)
//       const originalData = apiResponse?.result && apiResponse?.result[0]?.values
//       // const xAxisData1 =
//       //   originalData &&
//       //   originalData?.map((item: any) =>
//       //     new Intl.DateTimeFormat('en-US', {
//       //       year: 'numeric',
//       //       month: '2-digit',
//       //       day: '2-digit',
//       //       hour: 'numeric',
//       //       minute: '2-digit',
//       //       second: '2-digit',
//       //     }).format(new Date(item[0] * 1000))
//       //   )
//       const xAxisData1 =
//         originalData &&
//         originalData?.map((item: any) =>
//           new Date(item[0] * 1000)
//             .toISOString()
//             .replace(/T/, ' ')
//             .replace(/\.\d+Z$/, '')
//         )
//       console.log(xAxisData1)
//       const packets = originalData && originalData?.map((item: any) => item[1])
//       setPacketData({ xAxis: xAxisData1, packets: packets })
//     }
//   }, [apiResponse])
//   return (
//     <div>
//       {packetsDataGraph2.length === 0 ? (
//         <Box
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           sx={{ height: 250, width: '100%', bgcolor: '#f5f5f5', p: 4, pt: 1, pb: 2 }}
//         >
//           <h5>No Data</h5>
//         </Box>
//       ) : (
//         <ReactEcharts option={optionsGraph2} />
//       )}
//     </div>
//   )
// }
